import React from "react";
import { Link } from "react-router-dom";
import {BiLeftArrowAlt} from "react-icons/bi"

const Retour=()=>{
    return(
        <>
            <Link to="/quisommesnous" style={{width:"140px", marginTop:"-1.5cm"}} className="primary-btn back" id="aaa">
                <span><BiLeftArrowAlt style={{fontSize:"25px"}}/></span><span style={{marginLeft:"1cm", marginTop:"-3cm"}}><p style={{marginLeft:"1cm", marginTop:"-1.25cm", cursor:"pointer"}}>Retour</p></span>
            </Link>
        </>
    )
}
export default Retour;