import React, { useState , useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Navbaar } from "../Navbar/Navbaar";
import {BiLeftArrowAlt} from "react-icons/bi"
import Sdata from "../Destination/Sdata";
import EmptyFile from "../EmptyFile/EmptyFile";
import "./SinglePage.css"
import {FaPhoneAlt} from "react-icons/fa"
import {ImLocation} from "react-icons/im"
import {BsEnvelopeFill} from "react-icons/bs"
import Aide from "../Aide/Aide";
import Footer from "../Footer/Footer";
import {Date} from "../Date/Date"
import car from "../../pic/car.jpg"
import cc from "../../pic/cc.webp"
import lll from "../../pic/lll.jpg"
import {FaRocket} from "react-icons/fa"
import Retour from "./Retour";
import ok from "../../pic/ok.png"
import { height } from "@mui/system";
import But from "./But";
import {BiArrowToRight} from "react-icons/bi"
import { Component } from "react";
import axios from "axios"
import location from "../../pic/location.png"
import mail from "../../pic/mail.png"
import call from "../../pic/call.png"
import Brandingg from "../../pages/Flotte/Brandingg"

class SinglePageee extends Component{


    state={
        file : null
    }

    handleFile(e){
        let file = e.target.files[0]
        this.setState({file : file})
    }

    handleUpload(e){

        let file = this.state.file

        let formdata = new FormData()

        formdata.append('image',file)

        formdata.append('name',"Arjun Youjan")

        axios({
            url:`/some/api`,
            method:"POST",
            headers:{
                authorization:`your token`
            },
            data : formdata
        }).then((res)=>{

        }, (err)=>{

        })
    }


    render(){
    return (
        <>
                    
            <div className="uii">
                <div style={{background:"#fff"}}>
                    <Date/>
                    <hr style={{ color : 'gray'}}/>
                </div>
            

            <br/><br/><br/><br/><br/>

            <section className="quisommesnous">
                <div className="header4"><br/>
                    <div className="aaaa" style={{backgroundColor : "rgba(0,0,0,0.8)", color:"#fff", width:"50%", marginLeft:"2cm", borderRadius:"50px"}}>
                        <br/><br/><br/>
                        <hr style={{width:"9%", height:".1cm", color:"#c61612", marginLeft:"4cm", opacity:"1"}}/>
                        <h4 style={{marginLeft:"4cm", fontSize:"32px"}}>Qui sommes nous ?</h4><br/>
                        <p style={{marginLeft:"4cm", fontSize:"17px", color:"#fff"}}>Nous recrutons : Votre carrière chez <span style={{color:"#c61612"}}>R </span>& <span style={{color:"#c61612"}}>K </span>Transport !</p><br/><br/>
                    </div>
                </div>
            </section>
            </div>

            <div className="uii">
            <br/><br/><br/>
            <section style={{ width:"95%",marginLeft:"1.2cm",  backgroundColor:"#fff", boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
            <br/><div className="line" style={{marginTop:"1.2cm"}}></div>
            <section className="single-page top">
                <div className="container">
                    <div className="aboutCard mtop flex_space" style={{marginTop:"0cm", width:"100%"}}>
                        <div className="row row1" style={{width:"95%"}}>
                            <h1 >Vous souhaitez nous aider à  <span>révolutionner</span> le secteur <br/>du transport et logistique !</h1>
                        </div>
                    </div><br/>
                        <Retour/>
                    
                    <article className="content flex">
                        <div className="main-content">
                            <p style={{marginTop:"-1cm"}}></p>

                            <h1 style={{color:"#c61612"}}>Votre Carrière</h1>
                            <p>Nous sommes toujours à la recherche de personnes passionnées</p>
                            <div className="image grid1">
                                <img src={cc} alt="" className="imaage" style={{height:"230px"}}/>
                                <img src={lll} alt="" className="imaage" style={{height:"230px"}}/>
                            </div><br/><br/>
                            <h5 style={{color:"gray", fontSize:"18px"}}>Changez de voie</h5>
                            <p>Votre motivation est pour nous plus importante que des notes, des diplômes ou un CV complet. Chaque changement est synonyme de progrès et nous le savons.</p>
                            <h5 style={{color:"gray", fontSize:"18px"}}>Esprit d'équipe</h5>
                            <p>Si vous êtes à la recherche d'une structure hiérarchisée et stricte, vous n'êtes pas au bon endroit. Nous travaillons sur un pied d'égalité où tout le monde à son mot à dire.</p>
                            <h5 style={{color:"gray", fontSize:"18px"}}>Esprit libre</h5>
                            <p>Vous aimez travailler de manière indépendante et sur une base de confiance. Pour nous, c'est le résultat qui compte, pas le nombre d'heures travaillées.</p>
                            <h5 style={{color:"gray", fontSize:"18px"}}>Pensez différemment</h5>
                            <p>Vous ne serez pas enfermé dans une boîte de pensée. Trouvez vos propres solutions innovantes, loin des schémas habituels.</p>
                            
                            <div className="side-content" style={{marginTop:"-20cm", width:"20px", marginLeft:"24cm"}}>
                            <div className="box" style={{height:"12.5cm"}}>
                                <br/><h2 style={{fontSize:"21.5px"}}>Rejoignez l'équipe de <span style={{color:"#c61612"}}>R</span >&<span style={{color:"#c61612"}}>K Transport </span>!</h2><br/>
                                <br/><span><ImLocation style={{fontSize:"24px", color:"#c61612"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"17px"}}>&nbsp;&nbsp;&nbsp;<span><p style={{fontSize:"18px", marginLeft:"1cm", marginTop:"-1.2cm"}}>Adresse</p></span></span></span><br/><p style={{color:"gray", marginTop:"-1cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 Rue Marcel Dassault, 69740 Genas</p>
                                <span><FaPhoneAlt style={{fontSize:"24px", color:"#c61612"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"17px"}}>&nbsp;&nbsp;&nbsp;&nbsp;<span><p style={{fontSize:"18px", marginLeft:"1cm", marginTop:"-1.2cm"}}>Téléphone</p></span></span></span><br/><p style={{color:"gray", marginTop:"-1cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="tel:+788073409" className='mmm' >0487627661</a></p>
                                <span><BsEnvelopeFill style={{fontSize:"24px", color:"#c61612"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"17px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span><p style={{fontSize:"18px", marginLeft:"1cm", marginTop:"-1.2cm"}}>E-mail</p></span></span></span><br/><p style={{color:"gray", marginTop:"-1cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href = "mailto: contact@rktransport.fr" className='mm'>contact@rktransport.fr</a></p>
                                <Link to="/contact" style={{cursor:"pointer"}}>
                                    <button className="outline-btn" id="bbb" style={{marginLeft:"1.5cm", outline:"none", border:"none",marginTop:"0.2cm", background: "#c61612", color:"#fff", height:"50px"}}>
                                        <FaPhoneAlt className="icone" style={{height:"20px", width:"20px", marginLeft:"-.3cm", marginTop:"0cm"}}/>&nbsp;&nbsp;&nbsp;<span><p style={{marginLeft:".7cm",marginTop:"-1.1cm",fontSize:"16px", color:"white", cursor:"pointer"}}>Contactez-nous</p></span>
                                    </button>
                                </Link>
                                </div>  
                        </div>
                        

                        <div style={{marginTop:"9cm", marginLeft:"-1.7cm"}}>
                            <h1 className="heaa">
                                <span>Q</span>
                                <span>u</span>
                                <span>'</span>
                                <span>e</span>
                                <span>s</span>
                                <span>t</span>
                                <span className="space"></span>
                                <span>c</span>
                                <span>e</span>
                                <span className="space"></span>
                                <span>Q</span>
                                <span>u</span>
                                <span>i</span>
                                <span className="space"></span>
                                <span>V</span>
                                <span>o</span>
                                <span>u</span>
                                <span>s</span>
                                <span className="space"></span>
                                <span>A</span>
                                <span>t</span>
                                <span>t</span>
                                <span>e</span>
                                <span>n</span>
                                <span>d</span>
                                <span>?</span>
                            </h1><br/><br/><br/><br/><br/><br/><br/>


                            <div style={{width:"160vh"}}>
                                <div className="ig" style={{width:"60vh", marginLeft:"-22cm"}}>
                                    <img src={ok} classNameigg alt="" style={{marginLeft:"22cm", marginTop:"2Wcm"}}/>
                                </div>  <br/>

                                <div className="txtt" style={{width:"96vh", marginLeft:"15cm",marginTop:"-13.5cm"}}>
                                    <h5 style={{color:"#c61612", fontSize:"19px"}}><FaRocket/>&nbsp;&nbsp;
                                    <span style={{color:"gray"}}><p style={{marginTop:"-1.17cm", marginLeft:".9cm", fontSize:".5cm"}}>Horaires de travail flexibles</p></span>
                                    </h5>
                                    <p>Nous savons que tout le monde n'est pas le plus productif de 9 heures à 17 heures. C'est pourquoi nous encourageons nos employés à établir leurs propres horaires de travail flexibles qui sont coordonnés avec leurs équipes respectives.</p>

                                    <h5 style={{color:"#c61612", fontSize:"19px"}}><FaRocket/>&nbsp;&nbsp;
                                    <span style={{color:"gray"}}><p style={{marginTop:"-1.17cm", marginLeft:".9cm", fontSize:".5cm"}}>Bureaux branchés</p></span>
                                    </h5>
                                    <p>Que ce soit directement au cœur de Vienne, ou dans le quartier branché de Berlin, nos bureaux sont bien plus qu'un simple lieu de travail.</p>

                                    <h5 style={{color:"#c61612", fontSize:"19px"}}><FaRocket/>&nbsp;&nbsp;
                                    <span style={{color:"gray"}}><p style={{marginTop:"-1.17cm", marginLeft:".9cm", fontSize:".5cm"}}>Evénements d'équipe réguliers</p></span>
                                    </h5>
                                    <p>Nous sommes toujours heureux de nous retrouver pour des déjeuners d'équipe ou une petite pause pour se détendre. De plus, les événements de team-building et de fête sont au cœur de nos rencontres sociales, réunissant les collègues basés à Berlin et à Vienne pour célébrer nos succès et notre croissance.</p>

                                    <h5 style={{color:"#c61612", fontSize:"19px"}}><FaRocket/>&nbsp;&nbsp;
                                    <span style={{color:"gray"}}><p style={{marginTop:"-1.17cm", marginLeft:".9cm", fontSize:".5cm"}}>Boissons & Snacks</p></span>
                                    </h5>
                                    <p>Nous savons que travailler sur notre mission nécessite une énergie suffisante. C'est pourquoi nous offrons du café, des boissons, des fruits frais, des collations saines (et moins saines), pour être pleins d'énergie chaque jour.</p>
                                </div><br/><br/>

                                
                            </div>
                        </div>

                        </div>


                    </article>
                </div>
            </section>
            </section>
            </div>
            <div className="uii">
            <br/><br/>
            <div className="quisommesnous" style={{width:"95%",marginLeft:"1.2cm",  backgroundColor:"#fff", boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
            <div className="header6"><br/>
                    <div className="aaaa" style={{backgroundColor : "rgba(0,0,0,0.4)", color:"#fff", width:"100%", height:"100%", marginTop:"-.7cm"}}>
                        <br/><br/><br/>
                        <h4 className="text-center" style={{ fontSize:"35px"}}>Qui sommes nous ?</h4>
                        <hr style={{width:"15%", height:".12cm", color:"#c61612", marginLeft:"14.9cm", opacity:"1"}}/><br/>
                        <p style={{fontSize:"18px", color:"#fff"}} className="text-center">Un savoureux mélange de chauffeurs, de transporteurs, de techniciens, d'amoureux des mots et de touche-à-tout. Pour en savoir plus sur notre équipe, cliquez ci-dessous.</p><br/>
                        <Link to="/singlepage">
                            <button className="outline-btn" id="io" style={{marginLeft:"15.2cm", outline:"none", marginTop:"0.2cm", background: "#c61612", border:"2px solid #c61612", color:"#fff", height:"50px"}}>
                                <BiArrowToRight className="icone" style={{height:"20px", width:"20px", marginLeft:"-.3cm", marginTop:"0cm", color:"#fff"}}/>&nbsp;&nbsp;&nbsp;<span><p style={{marginLeft:".7cm",marginTop:"-1.1cm",fontSize:"16px", color:"#fff", cursor:"pointer"}}>Notre équipe</p></span>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            </div>
            <div className="uii">
            <br/><br/>
            <div style={{width:"95%",marginLeft:"1.2cm",  backgroundColor:"#fff", boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
            <br/><br/><br/><section className="secttt">
                                <form className="cv" style={{width:"300px", marginLeft:"0cm"}}>
                                    <div className="contactInfoo" style={{height:"490px"}}>
                                        <div>
                                            <h2>Pour nous joindre !</h2><br/><br/>
                                            <ul className="infoo">
                                                <li className="llii">
                                                    <span ><img  src={location} alt=""/></span>
                                                    <span>
                                                        1 rue Marcel Dassault,<br/>
                                                        69740 Genas,<br/>
                                                        Lyon
                                                    </span>
                                                </li>

                                                <li className="llii">
                                                    <span ><img  src={mail} alt=""/></span>
                                                    <span>
                                                        contact@rktransport.fr
                                                    </span>
                                                </li><br/>
                                                <li className="llii">
                                                    <span ><img  src={call} alt=""/></span>
                                                    <span>
                                                        0487627661
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="contactFormm" style={{height:"670px"}}>
                                        {/*item.devis */}
                                        <h2><span style={{color:"#c6302d"}}>Postulez</span> chez <span style={{color:"#c6302d"}}>R</span>& <span style={{color:"#c6302d"}}>K</span> Transport !</h2>
                                        <div className="formBoxx">
                                            <div className="inputBoxx w50">
                                                <label htmlFor="nom"></label>
                                                <input type="text" required name="nom" className="nom"/>
                                                {/*item.nom */}
                                                <span htmlFor="nom">Votre nom</span>
                                            </div>

                                            <div className="inputBoxx w50">
                                                <label htmlFor="email"></label>
                                                <input type="text" required name="email" className="email"/>
                                                {/*item.email */}
                                                <span htmlFor="email">Votre E-mail</span>
                                            </div>

                                            <div className="inputBoxx w50"><br/>
                                                <label htmlFor="telephone"></label>
                                                <input type="number" required name="telephone" className="telephone"/>
                                                {/*item.telephone */}
                                                <span htmlFor="telephone">Votre Téléphone</span>
                                            </div>

                                            <div className="inputBoxx w50">
                                                <label htmlFor="date" style={{color:"#c6302d"}}>Date</label>
                                                <input type="date" required name="date" className="date"/>
                                                {/*item.datee */}
                                                <span htmlFor="date"></span>
                                            </div>
                                            <div className="inputBoxx w100"><br/>
                                                <label htmlFor="file" style={{color:"#c6302d"}}>Sélectionner votre CV</label>
                                                <input style={{color:"black"}} required type="file" multiple name="file" onChange={(e)=>this.handleFile(e)} />
                                                <span htmlFor="file"></span>
                                            </div>

                                            <div className="inputBoxx w100">
                                                <label htmlFor="message"></label>   
                                                <textarea required name="message" className="message"></textarea>
                                                <span htmlFor="message">Saisir votre message....</span>
                                            </div>
                                            
                                            <div className="inputBoxx w100">
                                                <input type="submit" onClick={(e)=>this.handleUpload(e)}></input><div className="msg">Message envoyé !</div>
                                            </div>
                                    </div>
                                </div>
                            </form>
                        </section><br/><br/><br/>
            </div><Brandingg/>
            <Footer/>
            </div>

        <div style={{marginTop:"-114.5cm",background:"#fff"}}>
            <Navbaar/>
        </div>
        </>
    );}
}
export default SinglePageee;