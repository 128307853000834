
const LogistiqueData=[
    {
        id:1,
        title:"Stockage adapté et sécurisé",
        desc:"L’entrepôt logistique est comme une fourmilière en perpétuel mouvement, dans laquelle la sécurité des marchandises reste un enjeu majeur. R&K Transport préserve vos produits stockés ou en transit, de manière optimale.",
        cover:require("../../../pic/accc.jpg"),
    },
    {
        id:2,
        title:"Préparation de commandes",
        desc:"La préparation de commandes est une opération principale que R&K Transport assure et essaye de l'optimiser, pour permettre à la fois de gagner en productivité et à améliorer la qualité de service pour nos clients.",
        cover:require("../../../pic/accc.jpg"),
    },
    {
        id:3,
        title:"Gestion de Stock",
        desc:"Nos solution apporte une vision complète de vos stocks en temps réel tout en optimisant l’espace et l’organisation de votre entrepôt pour garantir l’optimisation, la fiabilité et la rapidité de vos préparations de commandes pour satisfaire la livraison courte à nos clients.",
        cover:require("../../../pic/accc.jpg"),
    },
]
export default LogistiqueData