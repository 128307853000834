import React, { useState } from "react";
import Footer from "../../Footer/Footer";
import {Date} from "../../Date/Date" 
import {ImLocation} from "react-icons/im";
import {FaPhoneAlt} from "react-icons/fa"
import {BsEnvelopeFill} from "react-icons/bs"
import {Navbaar} from "../../Navbar/Navbaar"
import location from "../../../pic/location.png"
import mail from "../../../pic/mail.png"
import call from "../../../pic/call.png"
import "./BlogSingle.css"
import {FaPlayCircle} from "react-icons/fa"
import Videoback from "./Videoback";
import Urgents from "./Urgents";
import { Link } from "react-scroll";
import Discrets from "./Discrets";
import Speciaux from "./Speciaux";
import Nationaux from "./Nationaux";
import Retour from "./Retour";
import Buut from "./Buut"
import Brandinggg from "../../../pages/Flotte/Brandinggg";
import {send} from "emailjs-com";

const BlogSingle=()=>{
    const[nom,setnom] = useState('');
    const[email,setemail] = useState('');
    const[societe,setsociete] = useState('');
    const[date,setdate] = useState('');
    const[chargement,setchargement] = useState('');
    const[livraison,setlivraison] = useState('');
    const[telephone,settelephone] = useState('');
    const[message,setmessage] = useState('');

    const handleNom = (e) =>{
        setnom(e.target.value)
    }
    const handleChargement=(e)=>{
        setchargement(e.target.value)
    }
    const handleDate=(e)=>{
        setdate(e.target.value)
    }
    const handleEmail=(e)=>{
        setemail(e.target.value)
    }
    const handleLivraison=(e)=>{
        setlivraison(e.target.value)
    }
    const handleMessage=(e)=>{
        setmessage(e.target.value)
    }
    const handleSociete=(e)=>{
        setsociete(e.target.value)
    }
    const handleTelephone=(e)=>{
        settelephone(e.target.value)
    }

    const sendMailExpress=(e)=>{
        e.preventDefault();
        send(
            'service_0cxb0yt',
            'template_ihi17ss',
            {nom, email, telephone, date, societe, chargement, livraison, message},
            'TOdirsB0kIQnFJG6H'
        )
        .then((response) => {
            console.log('message envoyé', response.status, response.text)
        })
        .catch((err) => {
            console.log('failed...',err)
        })
        setnom('');
        settelephone('');
        setdate('');
        setsociete('');
        setemail('');
        setchargement('');
        setlivraison('');
        setmessage('');
    }

    return (
        <>
            <Date/>
            <hr style={{ color : 'gray'}}/>
            <div style={{width:"100%"}}></div>
            <Navbaar/>
            
            <Videoback/>

            <div style={{backgroundColor:"#e9eff2", marginTop:"-2.6cm"}}><br/>
            
            
            
            <section className="single-page top" style={{marginTop:"1cm"}}>
            <div style={{ width:"96.4%",height:"1800px", backgroundColor:"#fff", marginLeft:".8cm",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
            <br/><div className="line" style={{marginTop:"1.5cm"}}></div><br/><br/><br/>
                <div className="container">
                    <br/><br/>
                        <Retour/>
                    <article className="content flex">
                        <div className="main-content" style={{marginTop:"-1.64cm"}}>
                        <div className="category flex_space">
                           
                            <span>2015-2022</span>
                           
                            <label>Transport</label>
                        </div>
                       
                        <h1>Express !</h1>
                       
                        <p></p>
                            
                       
                        <h2 style={{color:"#c61612", fontSize:"25px"}}>La réponse à l’extrême urgence</h2>
                        <div className="para flex_space">
                           
                            <p style={{fontSize:"13.5px"}}>Rupture de chaîne, documents importants, urgence médicale, RESEAU EXPRESS contacte le chauffeur le plus proche du lieu d’enlèvement pour un transport de pli, colis ou palette en express par coursier dans des délais optimisés. L’enlèvement peut être immédiat, mais l’horaire peut également être à la convenance de l’expéditeur. Nous vous garantissons un transport express sans rupture de charge dans des conditions de sécurité optimales. Grâce à notre système de suivi, vous pouvez consulter l’avancement de votre course urgente en temps réel.</p>
                           
                            <p style={{fontSize:"13.5px"}}>Sur simple appel téléphonique ou via devis express, nous sommes en mesure de mettre à votre disposition immédiate un véhicule adapté à votre demande et son chauffeur. Tous nos partenaires sont sélectionnés avec des critères de qualité très stricts vous garantissant une fiabilité totale dans la réalisation de votre course urgente. L'utilisation d'un logiciel d'optimisation d'itinéraire est une garantie de fiabilité dans l'exécution de votre transport express.</p>
                        </div><br/><br/>


                            <Urgents/>
                            <Discrets/>
                            <Speciaux/>
                            <Nationaux/>
                            
                            <div className="side-content" style={{marginTop:"-30cm", width:"20px", marginLeft:"23.5cm"}}>
                                <div className="box" style={{height:"11.5cm"}}>
                                    <h2 style={{fontSize:"21.5px"}}>Rejoignez l'équipe de <span style={{color:"#c61612"}}>R</span >&<span style={{color:"#c61612"}}>K Transport </span>!</h2>
                                    <br/><span><ImLocation style={{fontSize:"24px", color:"#c61612"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"17px"}}>&nbsp;&nbsp;&nbsp;<span><p style={{fontSize:"18px", marginLeft:"1cm", marginTop:"-1.2cm"}}>Adresse</p></span></span></span><br/><p style={{color:"gray", marginTop:"-1cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 Rue Marcel Dassault, 69740 Genas</p>
                                    <span><FaPhoneAlt style={{fontSize:"24px", color:"#c61612"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"17px"}}>&nbsp;&nbsp;&nbsp;&nbsp;<span><p style={{fontSize:"18px", marginLeft:"1cm", marginTop:"-1.2cm"}}>Téléphone</p></span></span></span><br/><p style={{color:"gray", marginTop:"-1cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="tel:+487627661" className='mmm'>0487627661</a></p>
                                    <span><BsEnvelopeFill style={{fontSize:"24px", color:"#c61612"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"17px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span><p style={{fontSize:"18px", marginLeft:"1cm", marginTop:"-1.2cm"}}>E-mail</p></span></span></span><br/><p style={{color:"gray", marginTop:"-1cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href = "mailto: contact@rktransport.fr" className='mmm'>contact@rktransport.fr</a></p>
                                    <Buut/>
                                </div>  
                            </div>

                            <div className="side-content">
                            <div className="category-list" style={{marginTop:"-19cm", marginLeft:"23cm", width:"9cm"}}>
                                <h2 style={{fontSize:"25px"}}>Nos services </h2>
                                <hr/>

                                <ul>
                                    <li>
                                        <div className="ico">
                                           
                                            <FaPlayCircle/>
                                        </div>
                                       
                                        <Link to="urgents" style={{color:"black"}}><p style={{marginTop:"-.6cm", marginLeft:".7cm"}}>Transports Urgents</p></Link>
                                    </li>
                                    <li>
                                        <div className="ico">
                                           
                                            <FaPlayCircle/>
                                        </div>
                                       
                                        <Link to="discrets" style={{color:"black"}}><p style={{marginTop:"-.6cm", marginLeft:".7cm"}}>Transports Discrets</p></Link>
                                    </li>
                                    <li>
                                        <div className="ico">
                                           
                                            <FaPlayCircle/>
                                        </div>
                                       
                                        <Link to="speciaux" style={{color:"black"}}><p style={{marginTop:"-.6cm", marginLeft:".7cm"}}>Transports Spéciaux</p></Link>
                                    </li>
                                    <li>
                                        <div className="ico">
                                           
                                            <FaPlayCircle/>
                                        </div>
                                       
                                        <Link to="nationaux" style={{color:"black"}}><p style={{marginTop:"-.6cm", marginLeft:".7cm"}}>Transports Nationaux et Internationaux</p></Link>
                                    </li>
                                </ul>
                                

                            </div>
                        </div>
                        </div>
                        </article>
                    </div>  
                    </div>
                    <br/><br/>

                    <div style={{ width:"96.4%",height:"1200px", backgroundColor:"#fff", marginLeft:"1cm",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
                        <article className="content flex">
                        <div className="main-content" style={{marginTop:"1cm"}}>
                           
                            <h2 style={{color:"#c61612", marginLeft:"1.3cm", fontSize:"25px"}}>Le transport Express maîtrisé</h2>
                            <div className="para flex_space">
                               
                                <p style={{marginLeft:"1.6cm"}}>Grâce à nos outils de tracking (suivi de transport express en temps réel), nous pouvons connaître l'avancement de votre course et vous informer à chaque étape de la livraison. Nous pouvons mettre à votre disposition exclusive une large gamme de véhicules pouvant prendre en charge vos marchandises de 1 gramme à 25 tonnes, ainsi qu'un coursier.</p>

                            </div>
                            
                           
                            <h2 style={{color:"#000", marginLeft:"1.3cm", fontSize:"25px"}}>Le transport express depuis les principales villes d’Europe</h2>
                           
                            <h2 style={{color:"#c61612", marginLeft:"1.3cm", fontSize:"27px"}}>Demander Un devis !</h2>
                            <div className="para flex_space">
                               
                                <p style={{marginLeft:"1.6cm"}}>Véritable réseau européen de transport express, nous expédions vos marchandises depuis toutes les villes en France et en Europe pour une livraison directe par coursier, en messagerie express ou bien en affrètement. Pour un transport express frigorifique, de matériel lourd ou de matière dangereuse, contactez-nous au 0487627661 pour connaître nos délais d’intervention sur votre région.</p>
                            </div>

                            <br/>
                            <section className="secttt">
                                <form onSubmit={sendMailExpress} className="cv" style={{width:"300px", marginLeft:"11cm"}}>
                                    <div className="contactInfoo">
                                        <div>
                                            <h2>Pour nous joindre !</h2><br/><br/>
                                            <ul className="infoo">
                                                <li className="llii">
                                                    <span ><img  src={location} alt=""/></span>
                                                    <span>
                                                        1 rue Marcel Dassault,<br/>
                                                        69740 Genas,<br/>
                                                        Lyon
                                                    </span>
                                                </li>

                                                <li className="llii">
                                                    <span ><img  src={mail} alt=""/></span>
                                                    <span>
                                                        <a href = "mailto: contact@rktransport.fr" className='mm'>contact@rktransport.fr</a>
                                                    </span>
                                                </li><br/>
                                                <li className="llii">
                                                    <span ><img  src={call} alt=""/></span>
                                                    <span>
                                                        <a href="tel:+487627661" className='mm'>0487627661</a>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="contactFormm">
                                       
                                        <h2>Demander un devis <span style={{color:"#c6302d"}}>Express</span> !</h2>
                                        <div className="formBoxx">
                                            <div className="inputBoxx w50">
                                                <label htmlFor="nom"></label>
                                                <input value={nom} onChange={handleNom} type="text" required name="nom" className="nom"/>
                                               
                                                <span htmlFor="nom">Votre nom</span>
                                            </div>

                                            <div className="inputBoxx w50">
                                                <label htmlFor="email"></label>
                                                <input value={email} onChange={handleEmail} type="text" required name="email" className="email"/>
                                               
                                                <span htmlFor="email">Votre E-mail</span>
                                            </div>

                                            <div className="inputBoxx w50">
                                                <label htmlFor="telephone"></label>
                                                <input value={telephone} onChange={handleTelephone} type="number" required name="telephone" className="telephone"/>
                                               
                                                <span htmlFor="telephone">Votre Téléphone</span>
                                            </div>

                                            <div className="inputBoxx w50">
                                                <label htmlFor="societe"></label>
                                                <input value={societe} onChange={handleSociete} type="text" required name="societe" className="societe"/>
                                               
                                                <span htmlFor="societe">Nom de la société</span>
                                            </div>

                                            <div className="inputBoxx w50">
                                                <label htmlFor="date" style={{color:"#c6302d"}}>Date</label>
                                                <input value={date} onChange={handleDate} type="date" required name="date" className="date"/>
                                               
                                                <span htmlFor="date"></span>
                                            </div>

                                            <div className="inputBoxx w50"><br/>
                                                <label htmlFor="chargement" style={{color:"#c6302d"}}></label>
                                                <input value={chargement} onChange={handleChargement} type="chargement" required name="chargement" className="chargement"/>
                                               
                                                <span htmlFor="chargement">Point de chargement</span>
                                            </div>

                                            <div className="inputBoxx w50"><br/>
                                                <label htmlFor="livraison" style={{color:"#c6302d"}}></label>
                                                <input value={livraison} onChange={handleLivraison} type="livraison" required name="livraison" className="livraison"/>
                                               
                                                <span htmlFor="livraison">Point de livraison</span>
                                            </div>

                                            <div className="inputBoxx w100">
                                                <label htmlFor="message"></label>   
                                                <textarea value={message} onChange={handleMessage} name="message" className="message"></textarea>
                                                <span htmlFor="message">Saisir votre message....</span>
                                            </div>
                                            
                                            <div className="inputBoxx w100">
                                                <input type="submit" value="Envoyer" style={{borderRadius:"5px"}}></input>
                                            </div>
                                    </div>
                                </div>
                            </form>
                        </section><br/><br/><br/>


                        </div>



                    </article>
                    </div>
                <Brandinggg/>
                <Footer/>
            </section>
            </div>

        </>
    );
}
export default BlogSingle