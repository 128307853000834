import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import '../App.css';
import CarouselContainer from '../components/CarouselContainer/CarouselContainer';
import {Date} from '../components/Date/Date';
import Features from '../components/Features/Features';
import Footer from '../components/Footer/Footer';
import Faq from '../components/faq/Faq';
import Presentation from '../components/Presentation/Presentation';
import Card from "../components/Card/Card"
import Aide from "../components/Aide/Aide";
import Home from "../components/Home/Home";
import Ser from "../components/Home/ser";
import {BiArrowToRight} from "react-icons/bi"
import { Link } from "react-router-dom";
import Branding from "./Flotte/Branding";

const Accueil = () =>{
    return(
        <>
            <div>
                <Home/>
                <div>

                </div>
                <div style={{backgroundColor:"#e9eff2"}}>
                    <br/><br/><br/>
                    <div style={{ width:"90%", backgroundColor:"#fff", marginLeft:"2cm",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
                        <br/><Presentation/>
                        <Card/><br/>
                        <Features/><br/><br/>
                    </div>
                    <br/><br/>
                </div>
                

                <div className="uii">
           
            <div className="quisommesnous" style={{width:"90%",marginLeft:"2cm",  backgroundColor:"#fff", boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
            <div className="header6"><br/>
                    <div className="aaaa" style={{backgroundColor : "rgba(0,0,0,0.4)", color:"#fff", width:"100%", height:"100%", marginTop:"-.7cm"}}>
                        <br/><br/><br/>
                        <h4 style={{marginLeft:"7.5cm", fontSize:"33px"}}>Meilleur service chez R&K Transport</h4>
                        <hr style={{width:"15%", height:".12cm", color:"#c61612", marginLeft:"14cm", opacity:"1"}}/><br/>
                        <p style={{fontSize:"18px", color:"#fff", alignItems:"center"}} className="text-center">Pour chaque taille de colis, chaque destination dans la France et en Europe, nous avons le bon servicede transport et logistique !</p><br/>
                        <Link to="/services">
                            <button className="outline-btn" id="io" style={{marginLeft:"14.2cm", outline:"none", marginTop:"0.2cm", background: "#c61612", border:"2px solid #c61612", color:"#fff", height:"50px"}}>
                                <BiArrowToRight className="icone" style={{height:"20px", width:"20px", marginLeft:"-.3cm", marginTop:"0cm", color:"#fff"}}/>&nbsp;&nbsp;&nbsp;<span><p style={{marginLeft:".7cm",marginTop:"-1.1cm",fontSize:"16px", color:"#fff", cursor:"pointer"}}>Nos services</p></span>
                            </button>
                        </Link>
                    </div>
                </div>
            </div><br/><br/>
            </div>


                <div style={{backgroundColor:"#e9eff2"}}>
                    <div style={{ width:"90%", backgroundColor:"#fff", marginLeft:"2cm",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
                        <br/><br/>


                    <div className="aboutCard mtop flex_space" style={{marginTop:"0cm"}}>
                        <div className="row row1" style={{marginLeft:"1cm"}}>
                            <h4>A propos de nous</h4>
                            <h1>Votre <span>Satisfaction,</span> notre motivation !</h1>
                        </div>
                    </div>


                        <Faq/>
                        <br/><br/>
                        <Ser/><br/><br/>
                         
                    </div>
                    <br/>
                    <Branding/>
                    <Footer/>
                </div>
                
          
        </div>
        </>
    );
}
export default Accueil;