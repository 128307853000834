import React from "react";
import AllBlog from "./AllBlog";

const Blog = () =>{
    return (
        <>
            <AllBlog/>
        </>
    );
}
export default Blog;