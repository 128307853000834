import { Date } from "../Date/Date";
import logi from "../../pic/yyy.jpeg"
import { Navbaar } from "../Navbar/Navbaar";
import { Carousel } from "react-bootstrap";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';



export default function Error(){

    const images = [
        {
          title: 'Accueil',
          width: '100%',
          paath:require('../../pages/Accueil')
        },
      ];
      
      const ImageButton = styled(ButtonBase)(({ theme }) => ({
        position: 'relative',
        height: 200,
        [theme.breakpoints.down('sm')]: {
          width: '100% !important', // Overrides inline-style
          height: 100,
        },
        '&:hover, &.Mui-focusVisible': {
          zIndex: 1,
          '& .MuiImageBackdrop-root': {
            opacity: 0.15,
          },
          '& .MuiImageMarked-root': {
            opacity: 0,
          },
          '& .MuiTypography-root': {
            border: '4px solid currentColor',
          },
        },
      }));
      
      const ImageSrc = styled('span')({
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
      });
      
      const Image = styled('span')(({ theme }) => ({
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
      }));
      
      const ImageBackdrop = styled('span')(({ theme }) => ({
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
      }));
      
      const ImageMarked = styled('span')(({ theme }) => ({
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
      }));


    return(
        <>
            <Date/><hr style={{width:"80%", marginLeft:"3.7cm"}}/>
            <Navbaar/>
            <img src={logi} alt="" style={{width:"202vh" , marginLeft:".15cm"}}/>
            <Carousel.Caption style={{backgroundColor : "rgba(0,0,0,0.4)",top:" 8cm", bottom:"7cm" , color:"#fff" , height:"25%"}}>
                <br/><h3 style={{fontSize:"50px"}}>R<span style={{color:"#c61612"}}>&</span>K Transport</h3><br/>
                <p style={{color:"#fff" , fontSize:"20px"}}>La page demandée est introuvable !</p><br/><br/><br/><br/>
                
                <Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%', marginTop:"-2.43cm" }}>
                    {images.map((image) => (
                        <ImageButton focusRipple key={image.title} style={{ width: image.width}}>
                            <ImageBackdrop className="MuiImageBackdrop-root" />
                            <Image>  
                                <a href="/accueil" style={{color:"#fff"}}>
                                <Typography component="span" variant="subtitle1" color="inherit" sx={{ position: 'relative', p: 4, pt: 2, pb: (theme) => `calc(${theme.spacing(1)} + 6px)`, }} >
                                    {image.title}
                                    <ImageMarked className="MuiImageMarked-root" />
                                </Typography>
                                </a>
                            </Image>
                        </ImageButton>
                    ))}
                </Box>
            </Carousel.Caption>
            
        </>
    )
}