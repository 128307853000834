import React, { useState , useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Navbaar } from "../Navbar/Navbaar";
import {BiLeftArrowAlt} from "react-icons/bi"
import Sdata from "../Destination/Sdata";
import EmptyFile from "../EmptyFile/EmptyFile";
import "./SinglePage.css"
import {FaPhoneAlt} from "react-icons/fa"
import {ImLocation} from "react-icons/im"
import {BsEnvelopeFill} from "react-icons/bs"
import Aide from "../Aide/Aide";
import Footer from "../Footer/Footer";
import {Date} from "../Date/Date"
import obj from "../../pic/obj.png"
import ooo from "../../pic/ooo.jpg"
import oooo from "../../pic/oooo.jpg"
import  {FaHandPointRight} from "react-icons/fa"
import Retour from "./Retour";
import Branding from "../../pages/Flotte/Branding"

const SinglePagee = () =>{

    return (
        <>
            <Date/>
            <hr style={{ color : 'gray'}}/>
            <Navbaar/>

            <section className="quisommesnous">
                <div className="header5"><br/>
                    <div className="aaaa" style={{backgroundColor : "rgba(0,0,0,0.8)", color:"#fff", width:"50%", marginLeft:"2cm", borderRadius:"50px"}}>
                        <br/><br/><br/>
                        <hr style={{width:"9%", height:".1cm", color:"#c61612", marginLeft:"4cm", opacity:"1"}}/>
                        <h4 style={{marginLeft:"4cm"}}><span style={{color:"#c61626"}}>R</span><span style={{color:"white"}}>&</span><span style={{color:"#c61626"}}>K</span> Transport ..</h4><br/>
                        <p style={{marginLeft:"4cm", fontSize:"20px", color:"#fff"}}>Découvrez notre mission et vision !</p><br/><br/>
                    </div>
                </div>
            </section>
            <div style={{backgroundColor:"#e9eff2", marginTop:"0cm"}}><br/><br/>
            <div style={{ width:"90%", backgroundColor:"#fff", marginLeft:"2cm",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
            <br/><div className="line" style={{marginTop:"1.4cm"}}></div>
            <section className="single-page top">
                <div className="container">
                    
                    <Retour/>

                    <article className="content flex">
                        <div className="main-content">
                            <p style={{marginTop:"-1cm"}}>Nous aidons les entreprises à déplacer leurs marchandises rapidement, efficacement. En développant des partenariats étroits avec nos clients, nous nous assurons qu’ils obtiennent un service logistique qui complète leur propre qualité de service.</p>

                            <h1 style={{color:"#c61612"}}>Notre objectif, mission, vision !</h1>
                            <br/>
                            <div className="image grid1">
                                <img src={ooo} alt="" className="imaage" style={{height:"230px"}}/>
                                <img src={oooo} alt="" className="imaage" style={{height:"230px"}}/>
                            </div><br/><br/>
                            <div className="para flex_space">
                                <p>Nous sommes des spécialistes du transport et de la messagerie fournissant des véhicules dédiés pour assurer le service le plus rapide et le plus efficace pour nos clients !</p>
                            </div>
                            <img src={obj} alt="" style={{marginTop:".06cm", opacity:".9", width:"10cm"}}/><br/><br/><br/>
                            <br/><br/>
                            <div style={{width:"13cm", marginLeft:"11cm", marginTop:"-8.5cm"}}>
                                <h5 style={{color:"#c61612"}}><FaHandPointRight/>&nbsp;&nbsp;
                                <span style={{color:"gray"}}><p style={{marginTop:"-1.17cm", marginLeft:".9cm", fontSize:".5cm"}}>Notre objectif</p></span>
                                </h5>
                                <p style={{marginTop:"-.1cm"}}>R&K Transport a pour objectif d'organiser l'acheminement de vos marchandises et d'assurer le suivi et la bonne exécution du transport en vous appuyant sur un réseau fiable et contrôlé avec soin en France et en Europe, et ce dans les meilleures conditions.</p>
                            </div>
                            <div style={{width:"13cm", marginLeft:"11cm"}}>
                                <h5 style={{color:"#c61612"}}><FaHandPointRight/>&nbsp;&nbsp;
                                    <span style={{color:"gray"}}><p style={{marginTop:"-1.17cm", marginLeft:".9cm", fontSize:".5cm"}}>Notre mission</p></span>
                                </h5>
                                <p style={{marginTop:"-.1cm"}}>Nous aidons les entreprises à déplacer leurs marchandises</p>
                                <p style={{marginLeft:"-11cm", marginTop:"-.3cm"}}> rapidement, efficacement. En développant des partenariats étroits avec nos clients, nous nous assurons qu’ils obtiennent un service logistique qui complète leur propre qualité de service. Nous sommes des spécialistes du transport et de la messagerie fournissant des véhicules dédiés pour assurer le service le plus rapide et le plus efficace pour nos clients.</p>
                            </div>
                            <h5 style={{color:"#c61612"}}><FaHandPointRight/>&nbsp;&nbsp;
                            <span style={{color:"gray"}}><p style={{marginTop:"-1.17cm", marginLeft:".9cm", fontSize:".5cm"}}>Notre Vision</p></span>
                            </h5>
                            <p style={{marginTop:"-.1cm"}}>Il s’agit de remettre en question la pensée conventionnelle et de concevoir un service de logistique sur mesure adapté à votre activité et qui respecte l’environnement. Nous ajoutons de la valeur en travaillant avec vous pour concevoir et développer une solution logistique qui comprend les enjeux de demain.</p>

                            <h5 style={{color:"#c61612"}}>&nbsp;&nbsp;
                                <span style={{color:"gray"}}></span>
                            </h5>
                            <p></p>

                            <h5 style={{color:"#c61612"}}>&nbsp;&nbsp;
                                <span style={{color:"gray"}}></span>
                            </h5>
                            <p></p>

                        </div>

                        <div className="side-content" style={{marginTop:".5cm", width:"20px", marginLeft:".5cm"}}>
                            <div className="box" style={{height:"12.5cm"}}>
                                <br/><h2 style={{fontSize:"21.5px"}}>Rejoignez l'équipe de <span style={{color:"#c61612"}}>R</span >&<span style={{color:"#c61612"}}>K Transport </span>!</h2><br/>
                                <br/><span><ImLocation style={{fontSize:"24px", color:"#c61612"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"17px"}}>&nbsp;&nbsp;&nbsp;<span><p style={{fontSize:"18px", marginLeft:"1cm", marginTop:"-1.2cm"}}>Adresse</p></span></span></span><br/><p style={{color:"gray", marginTop:"-1cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 Rue Marcel Dassault, 69740 Genas</p>
                                <span><FaPhoneAlt style={{fontSize:"24px", color:"#c61612"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"17px"}}>&nbsp;&nbsp;&nbsp;&nbsp;<span><p style={{fontSize:"18px", marginLeft:"1cm", marginTop:"-1.2cm"}}>Téléphone</p></span></span></span><br/><p style={{color:"gray", marginTop:"-1cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="tel:+788073409" className='mmm' >0487627661</a></p>
                                <span><BsEnvelopeFill style={{fontSize:"24px", color:"#c61612"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"17px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span><p style={{fontSize:"18px", marginLeft:"1cm", marginTop:"-1.2cm"}}>E-mail</p></span></span></span><br/><p style={{color:"gray", marginTop:"-1cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href = "mailto: contact@rktransport.fr" className='mm'>contact@rktransport.fr</a></p>
                                <Link to="/contact" style={{cursor:"pointer"}}>
                                    <button className="outline-btn" id="bbb" style={{marginLeft:"1.5cm", outline:"none", border:"none",marginTop:"0.2cm", background: "#c61612", color:"#fff", height:"50px"}}>
                                        <FaPhoneAlt className="icone" style={{height:"20px", width:"20px", marginLeft:"-.3cm", marginTop:"0cm"}}/>&nbsp;&nbsp;&nbsp;<span><p style={{marginLeft:".7cm",marginTop:"-1.1cm",fontSize:"16px", color:"white", cursor:"pointer"}}>Contactez-nous</p></span>
                                    </button>
                                </Link>
                                </div>  
                        </div>

                    </article>
                </div>
            </section>
            </div>
            <Branding/>
            <Footer/>
            </div>
        </>
    );
}
export default SinglePagee;