import React from "react";
import yyy from "../../../pic/yyy.jpeg"

const Urgents=()=>{
    return(
        <div id="urgents">
            <hr/><br/>
            {/*item.cover */}
            <img src={yyy} alt="" style={{marginTop:".06cm", opacity:".8", height:"220px", width:"360px"}}/>
            <h4 style={{color:"#c61612", marginLeft:"10.6cm", marginTop:"-5.5cm", fontSize:"21px"}}>Transports Urgents</h4>
            <p style={{color:"black", marginLeft:"10.8cm", fontSize:"13.5px"}}>Le temps vous manque ? En tant que coursier express, pour tout transport urgent, nous sommes quotidiennement sur la route dans toute la France et dans les autres pays européens. Nos coursiers transportent rapidement et directement au destinataire tous vos envois emballés – que ce soit des documents, colis ou palettes.</p>
            <hr style={{marginTop:"-.08cm"}}/>
        </div>
        )
}
export default Urgents