

const GalleryData = [
    {
        img : require("../../pic/qua.jpg"),
        title : "Qualité garantie",
    },
    {
        img : require("../../pic/rap.jpg"),
        title : "Rapidité",
    },
    {
        img : require("../../pic/adap.jpg"),
        title : "Adaptabilité",
    },
    {
        img : require("../../pic/eff.jpg"),
        title : "Efficacité",
    },
    {
        img : require("../../pic/rig.jpg"),
        title : "Rigueur",
    },
    {
        img : require("../../pic/sec.jpg"),
        title : "Sécurité",
    },
]
export default GalleryData;