import React,{Component} from 'react';
import DayJS from "react-dayjs"
import { AttachEmail } from '@mui/icons-material';
import { PhoneInTalk } from '@material-ui/icons';
import { color } from '@mui/system';

export function Date (){

        

        return(
            <div style={{marginRight:"4.6cm"}}>

                    <div className='App' style={{cursor:"text", textAlign:"right",fontSize:"15px",paddingTop:".4cm", fontFamily:"Cocin"}}>
                        <span><AttachEmail id="ico" style={{fontSize:'14px' , color:"gray", color:"#000"}}/></span><span>
                        &nbsp;&nbsp;&nbsp;<span style={{color:"#000", fontSize:"12px" , color:"gray"}}><a href = "mailto: contact@rktransport.fr" className='mm'>contact@rktransport.fr</a></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span><PhoneInTalk style={{fontSize:'14px' , color:"gray", color:"#000"}}/></span><span>
                        &nbsp;&nbsp;&nbsp;<span style={{fontSize:"12px" , color:"gray"}}><a href="tel:+788073409" className='mm'>0487627661</a></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <DayJS element="span" style={{fontSize:"12px" , color:"black"}} format='DD-MM-YYYY / HH:mm:ss'/>
                    </div>


        
            </div>
        )
}
export default Date