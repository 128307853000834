import React from "react";
import "./Card.css";
import rr from "../../pic/rr.jpg";
import ee from "../../pic/ee.jpeg";
import yyy from "../../pic/yyy.jpeg";
import { Link } from "react-router-dom";


const Card =() =>{ 
        return (
            <div className="container">
                <main className="grid">
                    <article>
                        <img id="zoom-in" src={ee} alt="bbbbbb" style={{height:"190px"}}/>
                        <div className="text">
                            <br/>
                            <h4>Votre Logistique au bout des doigts !</h4>
                            <button className="home-btn" id="btn"><Link to="/blogsinglee" style={{color:"gray"}}> Voir plus</Link></button>
                        </div>
                    </article>

                    <article>
                        <img id="zoom-in" src={rr} alt="bbbbbb" style={{height:"190px"}}/>
                        <div className="text">
                            <br/>
                            <h4>De la prise en charge à la livraison !</h4>
                            <button className="home-btn" id="btn"><Link to="/blogsingle" style={{color:"gray"}}> Voir plus</Link></button>
                        </div>
                    </article>

                    <article>
                        <img id="zoom-in" src={yyy} alt="bbbbbb" style={{height:"190px"}}/>
                        <div className="text">
                            <br/>
                            <h4>Besoin d'un Transport quotidien ?</h4>
                            <button className="home-btn" id="btn"><Link to="/blogsingleee" style={{color:"gray"}}> Voir plus</Link></button>
                        </div>
                    </article>

                </main>
            </div>
        );
    
}

export default Card;