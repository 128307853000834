import React, { useState , useEffect } from "react";
import { useParams } from "react-router-dom";
import {BiLeftArrowAlt} from "react-icons/bi"
import EmptyFile from "../../EmptyFile/EmptyFile";
import Aide from "../../Aide/Aide";
import Footer from "../../Footer/Footer";
import {Date} from "../../Date/Date"
import BlogData from "../BlogData";
import {ImLocation} from "react-icons/im";
import {FaPhoneAlt} from "react-icons/fa"
import {BsEnvelopeFill} from "react-icons/bs"
import {Navbaar} from "../../Navbar/Navbaar"
import location from "../../../pic/location.png"
import mail from "../../../pic/mail.png"
import call from "../../../pic/call.png"
import "./BlogSingle.css"
import {FaPlayCircle} from "react-icons/fa"
import Head1 from "./Head1"
import acc from "../../../pic/acc.jpg"
import Card from "../../Card/Card";
import Gallery from "../../Gallery/Gallery";
import Logistique from "../Logistique/Logistique";
import { Link } from "react-scroll";
import Caart from "./Caart";
import Retour from "./Retour";
import Videobackk from "./Videobackk";
import { width } from "@mui/system";
import Buut from "./Buut";
import Brandingggg from "../../../pages/Flotte/Brandingggg";
import {send} from "emailjs-com";


const BlogSinglee = () =>{


    const[nom,setnom] = useState('');
    const[email,setemail] = useState('');
    const[societe,setsociete] = useState('');
    const[telephone,settelephone] = useState('');
    const[message,setmessage] = useState('');
    const[date,setdate] = useState('');

    const handleNom = (e) =>{
        setnom(e.target.value)
    }
    const handleEmail=(e)=>{
        setemail(e.target.value)
    }
    const handleMessage=(e)=>{
        setmessage(e.target.value)
    }
    const handleSociete=(e)=>{
        setsociete(e.target.value)
    }
    const handleTelephone=(e)=>{
        settelephone(e.target.value)
    }
    const handleDate=(e)=>{
        setdate(e.target.value)
    }

    const sendMaillogistique=(e)=>{
        e.preventDefault();
        send(
            'service_0cxb0yt',
            'template_o7j0oz6',
            {nom, email,date, telephone, societe, message},
            'TOdirsB0kIQnFJG6H'
        )
        .then((response) => {
            console.log('message envoyé', response.status, response.text)
        })
        .catch((err) => {
            console.log('failed...',err)
        })
        setnom('');
        setdate('');
        settelephone('');
        setsociete('');
        setemail('');
        setmessage('');
    }

        
    return (
        <>
            <Date/>
            <hr style={{ color : 'gray'}}/>
            <div style={{width:"100%"}}></div>
            <Navbaar/>
            <Videobackk/>
            <div style={{backgroundColor:"#e9eff2", marginTop:"-2.8cm"}}><br/>

            <section className="single-page top" style={{marginTop:"1cm"}}>
            <div style={{ width:"97%", backgroundColor:"#fff", marginLeft:".6cm",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
            <br/><div className="line" style={{marginTop:"1.5cm"}}></div>
                <div className="container">

                <Retour/>
                
                    <article className="content flex">
                        
                        <div className="main-content">
                            {/*item.cover */}<br/><br/>
                            <img className="imm" src={acc} alt="" style={{marginTop:".06cm", opacity:".9"}}/><br/><br/><br/>

                            <div className="category flex_space">
                                {/*item.date */}
                                <span>2015-2022</span>
                                {/*item.category */}
                                <label>Stockage</label>
                            </div>
                            {/*item.title */}
                            <h1 >Logistique !</h1>
                            {/*item.desc */}
                            <p></p>
                            
                            {/*item.txt */}
                            <h2 style={{color:"#c61612", fontSize:"25px"}}>Confiez Votre Stock et La Gestion de Votre Logistique à R&K Transport</h2>
                               

                            <div className="para flex_space">
                                {/*item.par */}
                                <p>Trouver sans plus attendre votre solution de stockage professionnel et logistique. Grâce à son réseau de logisticien, R&K Transport vous accompagne afin de trouver une solution adaptée à vos besoins de stockage et de logistique à Lyon et ses alentours.</p>
                            </div>

                            <div className="side-content">
                            <div className="category-list">
                                <div style={{marginTop:"-22cm", marginLeft:"23cm",width:"10cm"}}>
                                <h2 style={{fontSize:"25px"}}>Nos services </h2>
                                <hr/>

                                <ul>
                                    <li>
                                        <div className="ico">
                                            {/*item.icone1 */}
                                            <FaPlayCircle/>
                                        </div>
                                        {/*item.p1 */}
                                        <Link to="caart" style={{color:"black"}}><p style={{marginTop:"-.6cm", marginLeft:".7cm"}}>Stockage adapté et sécurisé</p></Link>
                                    </li>
                                    <li>
                                        <div className="ico">
                                            {/*item.icone2 */}
                                            <FaPlayCircle/>
                                        </div>
                                        {/*item.p2 */}
                                        <Link to="caart" style={{color:"black"}}><p style={{marginTop:"-.6cm", marginLeft:".7cm"}}>Préparation de commandes</p></Link>
                                    </li>
                                    <li>
                                        <div className="ico">
                                            {/*item.icone3 */}
                                            <FaPlayCircle/>
                                        </div>
                                        {/*item.p3 */}
                                        <Link to="caart" style={{color:"black"}}><p style={{marginTop:"-.6cm", marginLeft:".7cm"}}>Gestion de stock</p></Link>
                                    </li>
                                </ul>
                                </div>
                                
                                <div className="side-content" style={{marginTop:"12m", width:"20px", marginLeft:"22.6cm"}}>
                                <br/><br/><div className="box" style={{height:"11.5cm"}}>
                                    <h2 style={{fontSize:"21.5px"}}>Rejoignez l'équipe de <span style={{color:"#c61612"}}>R</span >&<span style={{color:"#c61612"}}>K Transport </span>!</h2>
                                    <br/><span><ImLocation style={{fontSize:"24px", color:"#c61612"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"17px"}}>&nbsp;&nbsp;&nbsp;<span><p style={{fontSize:"18px", marginLeft:"1cm", marginTop:"-1.2cm"}}>Adresse</p></span></span></span><br/><p style={{color:"gray", marginTop:"-1cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 Rue Marcel Dassault, 69740 Genas</p>
                                    <span><FaPhoneAlt style={{fontSize:"24px", color:"#c61612"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"17px"}}>&nbsp;&nbsp;&nbsp;&nbsp;<span><p style={{fontSize:"18px", marginLeft:"1cm", marginTop:"-1.2cm"}}>Téléphone</p></span></span></span><br/><p style={{color:"gray", marginTop:"-1cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="tel:+487627661" className='mmm' >0487627661</a></p>
                                    <span><BsEnvelopeFill style={{fontSize:"24px", color:"#c61612"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"17px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span><p style={{fontSize:"18px", marginLeft:"1cm", marginTop:"-1.2cm"}}>E-mail</p></span></span></span><br/><p style={{color:"gray", marginTop:"-1cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href = "mailto: contact@rktransport.fr" className='mmm'>contact@rktransport.fr</a></p>
                                    <Buut/>
                                </div>  
                            </div>

                            </div>
                        </div>

                        </div>
                        </article>
                        </div><br/><br/><br/><br/><br/><br/><br/><br/>
                        </div><br/><br/><br/>




                        <div style={{ width:"97%", backgroundColor:"#fff", marginLeft:".6cm",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
                        <br/><br/><article className="content flex">
                        <div className="main-content">
                            {/*item.txt1 */}
                            <h2 style={{color:"#000", fontSize:"18px", marginLeft:"1.3cm"}}>R&K Transport vous assure une gestion flexible de stock, ainsi qu'un stockage adapté et bien sécurisé !</h2>
                            <br/>
                            
                            

                            <div className="para flex_space">
                                {/*item.par1 */}
                                <p style={{marginLeft:"1.6cm"}}>Nos solutions en logistique ainsi que notre expertise vous permettront de gagner du temps et de l’argent. Confiez la chaîne logistique de votre entreprise à des spécialistes !</p>

                            </div>
                            
                            {/*item.txt2 */}<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                            <h2 style={{color:"#000", marginLeft:"1.6cm", fontSize:"25px"}}>R&K Transport facilite votre distribution !</h2>
                            {/*item.txt3 */}
                            <h2 style={{color:"#c61612", marginLeft:"1.6cm"}}>Demande de stockage</h2><br/>

                            <section className="secttt">
                                <form onSubmit={sendMaillogistique} className="cv" style={{width:"300px", marginLeft:"11cm"}}>
                                    <div className="contactInfoo" style={{height:"450px"}}>
                                        <div>
                                            <h2>Pour nous joindre !</h2><br/><br/>
                                            <ul className="infoo">
                                                <li className="llii">
                                                    <span ><img  src={location} alt=""/></span>
                                                    <span>
                                                        1 rue Marcel Dassault,<br/>
                                                        69740 Genas,<br/>
                                                        Lyon
                                                    </span>
                                                </li>

                                                <li className="llii">
                                                    <span ><img  src={mail} alt=""/></span>
                                                    <span>
                                                        <a href = "mailto: contact@rktransport.fr" className='mm'>contact@rktransport.fr</a>
                                                    </span>
                                                </li><br/>
                                                <li className="llii">
                                                    <span ><img  src={call} alt=""/></span>
                                                    <span>
                                                        <a href="tel:+487627661" className='mm'>0487627661</a>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="contactFormm" style={{height:"620px"}}>
                                        {/*item.devis */}
                                        <h2>Demander un devis <span style={{color:"#c6302d"}}>Logistique</span> !</h2>
                                        <div className="formBoxx">
                                            <div className="inputBoxx w50">
                                                <label htmlFor="nom"></label>
                                                <input value={nom} onChange={handleNom} type="text" required name="nom" className="nom"/>
                                                {/*item.nom */}
                                                <span htmlFor="nom">Votre nom</span>
                                            </div>

                                            <div className="inputBoxx w50">
                                                <label htmlFor="email"></label>
                                                <input value={email} onChange={handleEmail} type="text" required name="email" className="email"/>
                                                {/*item.email */}
                                                <span htmlFor="email">Votre E-mail</span>
                                            </div>

                                            <div className="inputBoxx w50">
                                                <label htmlFor="telephone"></label>
                                                <input value={telephone} onChange={handleTelephone} type="number" required name="telephone" className="telephone"/>
                                                {/*item.telephone */}
                                                <span htmlFor="telephone">Votre Téléphone</span>
                                            </div>

                                            <div className="inputBoxx w50">
                                                <label htmlFor="societe"></label>
                                                <input type="text" value={societe} onChange={handleSociete} required name="societe" className="societe"/>
                                                {/*item.societe */}
                                                <span htmlFor="societe">Nom de la société</span>
                                            </div>

                                            <div className="inputBoxx w50">
                                                <label htmlFor="date" style={{color:"#c6302d"}}>Date</label>
                                                <input type="date" value={date} onChange={handleDate} required name="date" className="date"/>
                                                {/*item.datee */}
                                                <span htmlFor="date"></span>
                                            </div>

                                            <div className="inputBoxx w100">
                                                <label htmlFor="message"></label>   
                                                <textarea value={message} onChange={handleMessage} required name="message" className="message"></textarea>
                                                <span htmlFor="message">Saisir votre message....</span>
                                            </div>
                                            
                                            <div className="inputBoxx w100">
                                                <input type="submit"></input><div className="msg">Message envoyé !</div>
                                            </div>
                                    </div>
                                </div>
                            </form>
                        </section><br/>


                        </div>



                    </article>
                    </div>
                
            <Brandingggg/>
            <Footer/>
            </section>  
            </div>
        <Caart/><br/><br/>
        </>
    );
}
export default BlogSinglee;