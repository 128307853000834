import React from "react";
import "./Flotte.css"
import {FaGlobeEurope} from "react-icons/fa"
import {GiCardboardBox} from "react-icons/gi"
import {RiCustomerService2Fill} from "react-icons/ri"


const Brandingg =() =>{

    const data=[
        {
            id:<FaGlobeEurope/>,
            heading:"Expédition Européenne",
            desc:"Transporter et stocker vos marchandises dans la France et l'Europe aux meilleures conditions."
        },
        {
            id:<GiCardboardBox/>,
            heading:"Gestion des retours",
            desc:"Processus optimisé de gestion des retours, contrôle qualité et remise en stock des envois."
        },
        {
            id:<RiCustomerService2Fill/>,
            heading:"Service client à l'écoute",
            desc:"La satisfaction de nos clients est notre priorité ! On vous apporte les réponses à vos interrogations."
        },
    ]


    return(
        <>
            <section className="brandingg">
                <div className="coont grrid">
                    {data.map((value)=>{
                        return <div className="bxx flx">

                            <div className="pra">
                                <div className="txte">
                                    <h1 className="iddd text-center" style={{marginLeft:"4cm", fontSize:"35px"}}>{value.id}</h1><br/>
                                </div>
                                <h2 className="text-center" style={{fontSize:"20px"}}>{value.heading}</h2>
                                <p className="text-center" style={{fontSize:"15px"}}>{value.desc}</p>
                            </div>
                        </div>
                    })}
                </div>
            </section>
        </>
    )
}
export default Brandingg