import React from "react";
import { Link } from "react-router-dom";
import {MdVerticalAlignTop} from "react-icons/md"

const But=()=>{
    return(
        <Link to="/singlepagee">
            <button className="outline-btn" id="bbb" style={{marginLeft:"12cm", outline:"none", marginTop:"0.2cm", background: "#fff", border:"2px solid #c61612", color:"#fff", height:"50px"}}>
                <MdVerticalAlignTop className="icone" style={{height:"20px", width:"20px", marginLeft:"-.3cm", marginTop:"0cm", color:"#c61612"}}/>&nbsp;&nbsp;&nbsp;<span><p style={{marginLeft:".7cm",marginTop:"-1.1cm",fontSize:"16px", color:"#c61612", cursor:"pointer"}}>Notre objectif</p></span>
            </button>
        </Link>
    )
}
export default But