import React from "react";
import {Date} from "../Date/Date";
import Hero from "./Hero";
import "./Home.css"
import {Navbaar} from "../Navbar/Navbaar"
import Header from "./Header";
 
const Home=()=>{
    return(
        <div>
            <Date/><hr style={{width:"80%", justifyContent:"center", alignItems:"center", marginLeft:"3.7cm"}} />
            
            <Header/>
            
            <Hero/>
            
        </div>
    )
}
export default Home