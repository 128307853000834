const Tdata = [
    {
        id:1,
        desc:"Depuis toujours, R&K Transport prend soin des marchandises qui lui sont confiés par ses clients tout au long de la chaine logistique.",
        name:"Redouane Belaamri",
        profile: require("../../pic/lll.jpg"),
        post:"Gérant",
    },
    {
        id:2,
        desc:"Nous faisons en sorte que les choses se fassent. Par-dessus tout, nous sommes passionnés par ce que nous faisons et nous visons à susciter l’enthousiasme de nos clients, rien de moins.",
        name:"Cyndi Moritz",
        profile: require("../../pic/lll.jpg"),
        post:"Responsable",
    },
    {
        id:3,
        desc:"Nous aidons les entreprises à déplacer leurs marchandises rapidement, efficacement. En développant des partenariats étroits avec nos clients, nous nous assurons qu’ils obtiennent un service logistique qui complète leur propre qualité de service.",
        name:"Hana",
        profile: require("../../pic/lll.jpg"),
        post:"Responsable",
    },
    {
        id:4,
        desc:"L'équipe de RK Transport dont j'étais stagiaire est professionnelle, réactive et motivée",
        name:"Khammassi Imen",
        profile: require("../../pic/lll.jpg"),
        post:"Stagiaire",
    },
    
]

export default Tdata;