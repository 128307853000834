import React, { Component } from "react";
import { Date } from "../../components/Date/Date";
import { Navbaar } from "../../components/Navbar/Navbaar";
import HeadTitle from "../../components/HeadTitle/HeadTitle"
import Blog from "../../components/Blog/Blog";
import "./Services.css"
import {TiTick} from "react-icons/ti"
import {FaQuoteLeft} from "react-icons/fa"
import ggg from "../../pic/ggg.png"
import Aide from "../../components/Aide/Aide";
import Footer from "../../components/Footer/Footer";
import Branding from "../Flotte/Branding"


export class Services extends Component{
    render(){
        return(
            <>
                <Date/><hr style={{ color : 'gray'}}/>
                <Navbaar/>
                <section className="quisommesnous">
                <div className="header2"><br/>
                    <div className="aaaa" style={{backgroundColor : "rgba(0,0,0,0.5)", color:"#fff", width:"50%", marginLeft:"2cm", borderRadius:"50px"}}>
                        <br/><br/><br/>
                        <hr style={{width:"9%", height:".1cm", color:"#c61612", marginLeft:"4cm", opacity:"1"}}/>
                        <h4 style={{marginLeft:"4cm"}}>Garder votre entreprise en mouvement</h4><br/>
                        <p style={{marginLeft:"4cm", fontSize:"20px", color:"#fff"}}>Transparents, adaptés et simples.</p><br/><br/>
                    </div>
                </div>
            </section>
            <div style={{backgroundColor:"#e9eff2"}}><br/><br/>
            <div style={{ width:"90%", backgroundColor:"#fff", marginLeft:"2cm",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
            <br/>
            <br/><div className="line"></div><br/>
            <p style={{textAlign:"center", color:"#878e99", fontSize:"17px", lineHeight:"30px", marginTop:"25px", width:"90%", marginLeft:"2.1cm"}}>Nous avons commencé notre activité en externalisant notre logistique : nous sommes donc idéalement placés pour comprendre votre situation et vous apporter le meilleur service dans le cadre d'une externalisation rentable.</p>
            <br/>
            <div className="aboutCard mtop flex_space" style={{marginLeft:"2cm"}}>
                <div className="">
                    <h4>Rien que pour vous, tout de suite, la France et l'Europe à portée de main</h4>
                    <h1>Nous tenons <span>les promesses,</span> que vous avez faites à vos clients !</h1><br/>
                    <FaQuoteLeft style={{color:"#bfbfbf", marginTop:".5cm", fontSize:"57px"}}/>
                </div>
                
            </div>
            <div style={{marginTop:"-1.3cm", marginLeft:"4.5cm", color:"#878e99"}}>
                <p>La réactivité est notre priorité : nous savons ce qu'est une urgence client et ferons tout pour la traiter rapidement car <br/> votre réussite est la nôtre.La souplesse : nous vous accueillerons toujours avec le sourire sans nous retrancher derrière <br/> des procédures. Nous avons déjà colisé un produit et vous souhaitez annuler la commande ou échanger du transport? <br/> Nous ferons le nécessaire avant son expédition.</p>
            </div>
            <p style={{marginLeft:"1.2cm", color:"#878e99"}}>La transparence  de notre modèle économique : notre objectif n'est pas de facturer du stockage ou de la réception de marchandise, mais de vous accompagner dans votre développement. Le client se doit d'avoir un service qui tend vers l'excellence, ceci est valable pour vous. <br/>R&K Transport et le choix rigoureux de nos outils sont là pour nous permettre d'atteindre cet objectif. Nous vous accompagnons avec des solutions qui vous aideront à vous développer.</p>
            
                <div style={{marginTop:"-1cm"}}>
                <Blog/>
            </div><br/><br/>
            </div><br/><br/><br/>
            </div>
            <div style={{backgroundColor:"#e9eff2"}}>
            <div style={{ width:"90%", backgroundColor:"#fff", marginLeft:"2cm",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}><br/>
            
            <h1 className="heaa" style={{marginLeft:"1.5cm"}}>
                    <span>N</span>
                    <span>o</span>
                    <span>s</span>
                    <span className="space"></span>
                    <span>V</span>
                    <span>a</span>
                    <span>l</span>
                    <span>e</span>
                    <span>u</span>
                    <span>r</span>
                    <span>s</span>
                    <span>!</span>
                </h1>
            
            
            <div style={{justifyContent:"center", alignItems:"center", textAlign:"center"}}>
                <span><TiTick style={{fontSize:"24px", color:"#c61612", marginLeft:"-10.2cm"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"16px"}}></span></span><p style={{color:"black", marginTop:"-.55cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Une équipe professionnelle, réactive et motivée</p>
                <span><TiTick style={{fontSize:"24px", color:"#c61612", marginLeft:"-12.4cm"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"16px"}}></span></span><p style={{color:"black", marginTop:"-.55cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vaste parc de véhicules pour s’adapter à tous les besoins</p>
                <span><TiTick style={{fontSize:"24px", color:"#c61612",marginLeft:"-5.35cm"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"16px"}}></span></span><p style={{color:"black", marginTop:"-.55cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tarification transparente</p>
                <span><TiTick style={{fontSize:"24px", color:"#c61612", marginLeft:"-9.7cm"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"16px"}}></span></span><p style={{color:"black", marginTop:"-.55cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Disponibles pour vous apporter des réponses</p>
                <span><TiTick style={{fontSize:"24px", color:"#c61612", marginLeft:"-16.85cm"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"16px"}}></span></span><p style={{color:"black", marginTop:"-.55cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Utilisation intelligente de l’analyse des données et de la planification d’itinéraire</p>
                <span><TiTick style={{fontSize:"24px", color:"#c61612",marginLeft:"-10cm"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"16px"}}></span></span><p style={{color:"black", marginTop:"-.55cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Processus et systèmes informatisés à la pointe</p>
            </div>

            <div className="aboutCard mtop flex_space" style={{marginLeft:"2cm"}}>
                <div className="row1">
                    <h1>Pourquoi <span style={{color:"#c61612"}}>R&K Transport </span>?</h1><br/>
                    <div style={{}}>
                        <span><TiTick style={{fontSize:"19px", color:"#bfbfbf"}}/></span><p style={{color:"gray", marginTop:"-.55cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Devis Personnalisés : Nous vous proposons une offre e-logistique correspondant à vos besoins avec des prix justes.</p>
                        <span><TiTick style={{fontSize:"19px", color:"#bfbfbf"}}/></span><p style={{color:"gray", marginTop:"-.55cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Partenaires d’expédition flexibles : Vous n’êtes lié à aucun transporteur et bénéficiez d’une grande flexibilité lors de l’envoi <br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;de colis pour garantir la meilleure qualité de livraison possible.</p>
                        <span><TiTick style={{fontSize:"19px", color:"#bfbfbf"}}/></span><p style={{color:"gray", marginTop:"-.55cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rapports d’expédition détaillés : Gardez un oeil sur tous vos envois avec le détail des coûts associés.</p>
                        <span><TiTick style={{fontSize:"19px", color:"#bfbfbf"}}/></span><p style={{color:"gray", marginTop:"-.55cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rapports détaillés des stocks : Suivi en temps réel de vos produits pour éviter les ruptures de stock.</p>
                        <span><TiTick style={{fontSize:"19px", color:"#bfbfbf"}}/></span><p style={{color:"gray", marginTop:"-.55cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Service client à votre écoute : Une équipe R&K Transport est mise à votre disposition.</p>
                        <span><TiTick style={{fontSize:"19px", color:"#bfbfbf"}}/></span><p style={{color:"gray", marginTop:"-.55cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;L'esprit d'équipe : Un pied d'égalité est là, où tout le monde à son mot dire</p>
                    </div>  <br/>
                </div>
                
            </div>
            </div>
            <Branding/>
            <Footer/>
            </div>

            </>
        );
    }
} 