import React, { useState , useEffect } from "react";
import {BiLeftArrowAlt} from "react-icons/bi"
import EmptyFile from "../../EmptyFile/EmptyFile";
import Aide from "../../Aide/Aide";
import Footer from "../../Footer/Footer";
import {Date} from "../../Date/Date"
import BlogData from "../BlogData";
import {ImLocation} from "react-icons/im";
import {FaPhoneAlt} from "react-icons/fa"
import {BsEnvelopeFill} from "react-icons/bs"
import {Navbaar} from "../../Navbar/Navbaar"
import "./BlogSingle.css"
import {FaPlayCircle} from "react-icons/fa"
import Head1 from "./Head1"
import acccc from "../../../pic/acccc.jpg"
import Videobackkk from "./Videobackkk";
import Ret from "./Ret";
import Foo from "./Foo"
import { Link } from "react-scroll";
import Brandinggggg from "../../../pages/Flotte/Brandinggggg";


const BlogSingleee = () =>{

        
    return (
        <>
            <Date/>
            <hr style={{ color : 'gray'}}/>
            <div style={{width:"100%"}}></div>
            <Navbaar/>
            <Videobackkk/>
            <div style={{backgroundColor:"#e9eff2", marginTop:"-2cm"}}><br/><br/>
            <div style={{ width:"93%", backgroundColor:"#fff", marginLeft:"1.5cm",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
            <br/><div className="line" style={{marginTop:"1.4cm"}}></div>

            <section className="single-page top">
                <div className="container">
                    <Ret/>
                    
                    <article className="content flex">
                        <div className="main-content">
                            {/*item.cover */}

                            <div className="category flex_space">
                                {/*item.date */}
                                <span>2015-2022</span>
                                {/*item.category */}
                                <label>Livraison</label>
                            </div>
                            {/*item.title */}
                            <h1>Messagerie !</h1>
                            {/*item.desc */}
                            <p></p>
                            
                            {/*item.txt */}
                            <h2 style={{color:"#c61612", fontSize:"25px"}}>Le transport en messagerie express</h2>
                            <div className="para flex_space">
                                {/*item.par */}
                                <p style={{fontSize:"13.5px"}}>Idéal pour les expéditions n’ayant pas un caractère de première urgence, nous livrons vos plis, vos colis et vos palettes en messagerie Express. Pour être performant tous les jours, vous avez besoin d'un partenaire pour le transport et la messagerie express qui respecte vos engagements.</p>
                            </div>

                            {/*item.txt1 */}
                            <div className="para flex_space">
                                {/*item.par1 */}
                                <p>Envois sur-mesure, pour tous les besoins d’expéditions hors normes, les envois sensibles ou les livraisons en extrême urgence. Solutions sectorielles : adaptées aux contraintes de certains secteurs comme la santé, les banques et les assurances, l’industrie automobile ou le monde de l’informatique, de l’électronique et de la téléphonie.</p>

                            </div>
                            
                            <img src={acccc} alt="" style={{marginTop:".06cm", opacity:".9"}}/><br/><br/><br/>
                            {/*item.txt2 */}
                            <h2 style={{color:"#000", fontSize:"25px"}}>Réduisez vos coûts et déchargez vous du souci de vos expéditions</h2>
                            {/*item.txt3 */}
                            <h2 style={{color:"#c61612"}}>Demande de messagerie</h2>
                            <div className="para flex_space">
                                {/*item.par2 */}
                                <p>Expéditions standards en express, à horaire garanti.</p>
                            </div><br/>

                            <Foo/>

                        </div>

                        <div className="side-content"><br/>
                            <div className="category-list" style={{marginLeft:"1cm"}}>
                                <h2 style={{fontSize:"25px"}}>Nos services </h2>
                                <hr/>

                                <ul>
                                    <li>
                                        <div className="ico">
                                            {/*item.icone1 */}
                                            <FaPlayCircle/>
                                        </div>
                                        {/*item.p1 */}
                                        <Link to="foo"><p style={{marginTop:"-.6cm", marginLeft:".7cm"}}>Livraison rapide et garantie !</p></Link>
                                    </li>
                                </ul>
                                
                                <div className="side-content" style={{marginTop:"2cm", width:"20px", marginLeft:"-.3cm"}}>
                            <div className="box" style={{marginLeft:"-1cm"}}>
                                <h2 style={{fontSize:"21.5px"}}>Rejoignez l'équipe de <span style={{color:"#c61612"}}>R</span >&<span style={{color:"#c61612"}}>K Transport </span>!</h2>
                                <br/><span><ImLocation style={{fontSize:"24px", color:"#c61612"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"17px"}}>&nbsp;&nbsp;&nbsp;<span><p style={{fontSize:"18px", marginLeft:"1cm", marginTop:"-1.2cm"}}>Adresse</p></span></span></span><br/><p style={{color:"gray", marginTop:"-1cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 Rue Marcel Dassault, 69740 Genas</p>
                                <span><FaPhoneAlt style={{fontSize:"24px", color:"#c61612"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"17px"}}>&nbsp;&nbsp;&nbsp;&nbsp;<span><p style={{fontSize:"18px", marginLeft:"1cm", marginTop:"-1.2cm"}}>Téléphone</p></span></span></span><br/><p style={{color:"gray", marginTop:"-1cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="tel:+788073409" className='mmm' >0487627661</a></p>
                                <span><BsEnvelopeFill style={{fontSize:"24px", color:"#c61612"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"17px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span><p style={{fontSize:"18px", marginLeft:"1cm", marginTop:"-1.2cm"}}>E-mail</p></span></span></span><br/><p style={{color:"gray", marginTop:"-1cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href = "mailto: contact@rktransport.fr" className='mmm'>contact@rktransport.fr</a></p>
                                <button className="outline-btn" id="bbb" style={{marginLeft:"1.5cm", outline:"none", border:"none",marginTop:"0.2cm", background: "#c61612", color:"#fff", height:"50px"}}>
                                    <FaPhoneAlt className="icone" style={{height:"20px", width:"20px", marginLeft:"-.3cm", marginTop:"0cm"}}/>&nbsp;&nbsp;&nbsp;<span><p style={{marginLeft:".7cm",marginTop:"-1.1cm",fontSize:"16px", color:"white"}}>Contactez-nous</p></span>
                                </button>
                            </div>  
                        </div>
                            </div>
                        </div>

                    </article>
                </div>
            </section>
        </div><Brandinggggg/>
        <Footer/>
        </div>
        </>
    );
}
export default BlogSingleee;