import React from "react";
import Allitems from "../../Destination/AllItems";

const DestinationHome = () =>{
    return (
            <section className="popular top">
                <div className="full_container">
                    <div className="heading">
                        <div className="line"></div>
                    </div>
                    
                    <div className="content" style={{marginTop:"-1.3cm", marginLeft:"-1.1cm"}}>
                        <Allitems/>
                    </div>
                </div>
            </section>
    );
}
export default DestinationHome;