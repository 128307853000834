import { GoChecklist } from "react-icons/go";
import { GiNuclearWaste} from "react-icons/gi";
import { FaTruck } from "react-icons/fa";
import { Ri24HoursFill } from "react-icons/ri"

export const featureList =[
    {
        id : 1,
        icon : <Ri24HoursFill color="black" size={22}/>,
        heading : "24 heures sur 24",
        text :"Assidues, discrètes et disponibles en permanence, nos équipes visent toujours l'excellence pour vous offrir le meilleur !",
    },
    {
        id : 2,
        icon : <FaTruck color="black" size={22}/>,
        heading : "Express Dédié !",
        text :"Une flotte diversifiée est disponible de véhicules pour la livraison. Pourquoi ne pas profiter de notre expertise ?",
    },
    {
        id : 3,
        icon : <GoChecklist color="black" size={22}/>,
        heading : "100% Satisfaction",
        text :"Soudés comme une famille, nous réalisons des transports de produits de luxe autant que des commandes régulières pour des e-commerces !",
    },
    {
        id : 4,
        icon : <GiNuclearWaste color="black" size={22}/>,
        heading : "Solutions collecte déchets ",
        text :"Besoin d’un service de collecte et/ou de transport de déchets qui respecte ses engagements? Vous pouvez compter sur nous !",
    },
    
]
