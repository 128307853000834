import React, { useState } from "react";
import logo from "../../pic/logo.png"
import {FaBars, FaTimes} from "react-icons/fa"
import "./Header.css"
import { Link } from "react-router-dom";
const Header =() =>{
    const [click , setClick] = useState(false)
    const handleClick = () => setClick(!click)

    const [color , setColor] = useState(false)
    const changeColor=()=>{
        if(window.scrollY >= 90){
            setColor(true)
        }else{
            setColor(false)
        }
    }
    window.addEventListener('scroll', changeColor)

    return(
        <div className={color ? 'header header-bg' : 'header'}>
            <div className="navbar">
                <a href="/" className="logo">
                    <img alt="" src={logo}/>
                </a>
                <div className="hamburger" onClick={handleClick}>
                    {click ? (<FaTimes size={30} style={{color:"#fff" , cursor:"pointer"}}/>) : <FaBars size={30} style={{color:"#fff" , cursor:"pointer"}}/>}
                </div>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <li className="nav-item">
                        <Link to="/"><br/>Accueil</Link>
                    </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <li className="nav-item">
                        <Link to="/quisommesnous"><br/>A Propos</Link>
                    </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <li className="nav-item">
                        <Link to="/services"><br/>Services</Link>
                    </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <li className="nav-item">
                        <Link to="/flotte"><br/>Flotte</Link>
                    </li>
                </ul>
                <div className='but'>
                        <Link to="/contact" id='cont' ><button className='home-btn' style={{color:"#fff"}}>Contactez-nous</button></Link>
                    </div>
            </div>
        </div>
    )
}
export default Header