import React from "react";
import vvv from "../../../videos/vvv.mp4"
import "./Video.css"

const Videobackk =()=>{
    return(
        <>
            <div className="textvid" style={{backgroundColor : "rgba(0,0,0,0.8)", color:"#fff", width:"50%", marginLeft:"2cm", borderRadius:"50px", position:"absolute", marginTop:"1.7cm"}}>
                <br/><br/><br/>
                <hr style={{width:"20%", height:".1cm", color:"#c61612", marginLeft:"4cm", opacity:"1"}}/>
                <h4 style={{marginLeft:"4cm"}}>livraisons garanties, coûts optimisés !</h4><br/>
                <p style={{marginLeft:"4cm", fontSize:"20px", color:"#fff"}}>Une expérience de Logistique Unique</p><br/><br/>
            </div>
            <div>
                <video id="video" autoPlay="autoplay" muted loop="infinite">
                    <source src={vvv} type="video/mp4"/>
                </video>
            </div>

        </>
    )
}
export default Videobackk