import React from 'react';
import { Carousel } from 'react-bootstrap';
import "../../App.css";
import accc from "../../pic/accc.jpg"
import acc from "../../pic/acc.jpg"
import acccc from "../../pic/acccc.jpg"

function CarouselContainer(){        
        return(            
                <main>
                <Carousel autoPlay interval={1500} infiniteLoop thumbWidth={120} showIndicators={false} showStatus={false} style={{top:"-.5cm"}}>
                    <Carousel.Item >
                        <img
                            style={{height:"700px"}}
                            className="d-block w-100"
                            src={accc}
                            alt="First slide"
                        />
                        <Carousel.Caption style={{backgroundColor : "rgba(0,0,0,0.6)",top:" 8cm", bottom:"7cm" , color:"#fff"}}>
                            <h3>Express ..!</h3>
                            <p style={{color:"#fff"}}>R&K Transport vous offre des services de transports urgents, discrets, spéciaux, nationaux et internationaux.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item >
                        <img
                            style={{height:"700px"}}
                            className="d-block w-100"
                            src={acc}
                            alt="Second slide"
                        />
                        <Carousel.Caption style={{backgroundColor : "rgba(0,0,0,0.6)",top:"8cm", bottom:"7cm" , color:"#fff"}}>
                            <h3>Logistique ..!</h3>
                            <p style={{color:"#fff"}}>R&K Transport assure une gestion flexible de stock ainsi qu'un stockage adapté et sécurisé.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            style={{height:"700px"}}
                            className="d-block w-100"
                            src={acccc}
                            alt="Third slide"
                        />
                        <Carousel.Caption style={{backgroundColor : "rgba(0,0,0,0.6)",top:"8cm", bottom:"7cm" , color:"#fff"}}>
                            <h3>Messagerie ..!</h3>
                            <p style={{color:"#fff"}}>R&K Transport vous offre un transport où les marchandises sont portés directement de l'expéditeur au destinataire.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </main>

                
        );
    }

export default CarouselContainer