import React from "react";
import {FaExternalLinkAlt} from "react-icons/fa";
import { Link } from "react-router-dom";
import jjj from "../../pic/jjj.webp"

const Dcard = () =>{
    return(
        <>
            <div className="items">
                <div className="img"> 
                    <img src={jjj} alt=""/>

                    <Link to="/singlepage" className="blogItem-link">
                        <FaExternalLinkAlt className="icone"/>
                    </Link>
                </div>

                <div className="title">
                    <h3 style={{fontSize:"17px", marginTop:".5cm"}}>Notre équipe</h3>
                </div>
            </div>
        </>
    );
}
export default Dcard;