import React from "react";
import log1 from "../../../pic/log1.jpg"
import "./Caart.css"
import zz from "../../../pic/zz.jpg"
import ee from "../../../pic/ee.jpeg"
import logi from "../../../pic/logi.jpg"

const Caart =()=>{
    return(
        <section className="caart">
            <div className="coon" id="caart">
            <div className="booxx" >
                    <div className="imgbooxx">
                        <img style={{height:"100%", width:"100%"}} src={zz} alt=""/>
                    </div>
                    <div className="conttent">
                        <h3>Stockage sécurisé</h3><br/>
                        <p>L’entrepôt logistique est comme une fourmilière en perpétuel mouvement, dans laquelle la sécurité des marchandises reste un enjeu majeur. R&K Transport préserve vos produits stockés ou en transit, de manière optimale.</p>
                    </div>
                </div>
                <div className="booxx">
                    <div className="imgbooxx">
                        <img style={{height:"100%", width:"100%"}} src={logi} alt=""/>
                    </div>
                    <div className="conttent">
                        <h3>Commandes !</h3><br/>
                        <p>La préparation de commandes est une opération principale que R&K Transport assure et essaye de l'optimiser, pour permettre à la fois de gagner en productivité et à améliorer la qualité de service pour nos clients.</p>
                    </div>
                </div>
                <div className="booxx">
                    <div className="imgbooxx">
                        <img style={{height:"100%", width:"100%"}} src={ee} alt=""/>
                    </div>
                    <div className="conttent">
                        <h3>Gestion de stock</h3><br/>
                        <p>Nos solution apporte une vision complète de vos stocks en temps réel tout en optimisant l’espace et l’organisation de votre entrepôt pour garantir l’optimisation, la fiabilité et la rapidité de vos préparations de commandes pour satisfaire la livraison courte à nos clients.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Caart