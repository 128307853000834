import React from "react";
import Allitems from "./AllItems";
import zz from "../../pic/zz.jpg"

const Testimonial = () =>{
    return(
        <>
            <div className="aboutCard mtop flex_space" style={{marginTop:"-1cm"}}>
                <div className="row row1">
                    <br/><br/><h4 style={{marginLeft:"3cm"}}>R&K Transport dans quelques mots...</h4>
                    <h1 style={{marginLeft:"3cm"}}>Notre <span>philosophie,</span> et nos valeurs !</h1>
                 </div>
            </div>
            <Allitems/>
        </>
    );
}
export default Testimonial;