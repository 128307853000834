import React from "react";
import { Component } from "react";
import { FaHotel } from "react-icons/fa";
import "./Ser.css"
import {MdEmojiTransportation,MdOutlineSecurity,MdDangerous,MdSentimentVerySatisfied} from "react-icons/md"
import {FaTruckMoving} from "react-icons/fa"
import {ImLocation2} from "react-icons/im"


export default class Ser extends Component{
    render(){
        return(
            <section className="ser" id="ser">
                <h1 className="heaa">
                    <span>V</span>
                    <span>o</span>
                    <span>t</span>
                    <span>r</span>
                    <span>e</span>
                    <span className="space"></span>
                    <span>S</span>
                    <span>a</span>
                    <span>t</span>
                    <span>i</span>
                    <span>s</span>
                    <span>f</span>
                    <span>a</span>
                    <span>c</span>
                    <span>t</span>
                    <span>i</span>
                    <span>o</span>
                    <span>n</span>
                    <span>!</span>
                </h1>
                <div className="box-cont">
                    <div className="boxxx">
                        <FaTruckMoving className="iic"/>
                        <h3>Transports Express</h3>
                        <p>Pour parer à vos besoins les plus urgents, nous intervenons au plus rapide et vous offrons un transport routier exclusif garantissant l'intégrité de votre marchandise</p>
                    </div>
                    <div className="boxxx">
                        <MdEmojiTransportation className="iic"/>
                        <h3>Liaisons programmées</h3>
                        <p>Pour satisfaire une demande particulière et régulière, AS TRANSPORTS JLF peut mettre en place des liaisons avec le véhicule adapté à l'heure de votre choix.</p>
                    </div>
                    <div className="boxxx">
                        <ImLocation2 className="iic"/>
                        <h3>Géo-localisation</h3>
                        <p>Par souci de qualité et d'évolution grâce aux technologies, R&K Transport a doté tous ses véhicules de GPS avec localisation et remontés d'informations.</p>
                    </div>
                    <div className="boxxx">
                        <MdOutlineSecurity className="iic"/>
                        <h3>Sécurisation du véhicule</h3>
                        <p>Afin de garantir la sécurité de votre marchandise durant le transport nous avons équipé tous nos véhicules de moyens de plombage.</p>
                    </div>
                    <div className="boxxx">
                        <MdDangerous className="iic"/>
                        <h3>Matières dangereuses</h3>
                        <p>Pour satisfaire à la demande de transports de matières dangereuses R&K Transport a aussi équipé ses véhicules et formé son personnel roulant selon la législation en vigueur.</p>
                    </div>
                    <div className="boxxx">
                        <MdSentimentVerySatisfied className="iic"/>
                        <h3>Votre satisfaction !    </h3>
                        <p>Nous sommes ici pour créer des expériences remarquables. Nous faisons en sorte que les choses se fassent. Par-dessus tout, nous visons à susciter l’enthousiasme de nos clients.</p>
                    </div>
                </div>   
            </section>
        )
    }
}
