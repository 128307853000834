import React, { useState } from "react";
import Dcard from "./Dcard";
import Dcardd from "./Dcardd";
import Dcarddd from "./Dcarddd";
import Sdata from "./Sdata";

const Allitems = () =>{
    return(
        <>
            <section className="gallery desi mtop">
                <div className="container">
                    <div className="content grid" style={{marginLeft:"-2.5cm"}}>
                        <Dcard/>
                        <Dcardd/>
                        <Dcarddd/>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Allitems;