import React, { Component } from 'react';
import { Navbar,Nav,NavDropdown } from 'react-bootstrap';
import "./Navbaar.css";  
import { Link } from 'react-router-dom';
export class Navbaar extends Component{
    render(){ 
    return(
        <>
        <Navbar style={{ borderRadius:"10px",  width:"100%"}}>
            <Navbar.Brand>
                <img alt="" expand='lg' collapseOnSelect style={{width : 130 , height:103 , marginLeft : '3cm',cursor : 'zoom-in' }} src={require('../../pic/logo.png')}/>{'   '}
            </Navbar.Brand>

            <Navbar.Toggle/>
            <Navbar.Collapse>
                <Nav> 
                    
                    <Nav.Link id="link" onselect="changercouleur()" class="fs-5 text"  style={{textDecoration : 'none', fontFamily:'Roboto , sans-serif'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Link className='acc' to="/">Accueil</Link></Nav.Link>
                    <Nav.Link id="link" onselect="changercouleur()" href="/quisommesnous" class="fs-5 text" style={{textDecoration : 'none' , fontFamily:'Roboto,sans-serif'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Link className='acc' to="/quisommesnous">Qui sommes nous ?</Link></Nav.Link>
                    <Nav.Link id="link" onselect="changercouleur()" href="/services" class="fs-5 text" style={{textDecoration : 'none' , fontFamily:'Roboto,sans-serif'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Link className='acc' to="/services">Nos services</Link></Nav.Link>
                    <Nav.Link id="link" onselect="changercouleur()" href="products" class="fs-5 text" style={{textDecoration : 'none' , fontFamily:'Roboto, sans-serif'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Link className='acc' to="/flotte">Notre Flotte</Link></Nav.Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Link to="/contact">
                        <div className='but' style={{marginTop:"-.1cm"}}>
                            <div id='cont' to="/contact"><button className='home-btn' style={{color:"#fff"}}>Contactez-nous</button></div>
                        </div>
                    </Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>

        
    </>

    );
    }
}


