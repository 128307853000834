import React from "react";
import { useLocation } from "react-router-dom";
import "../Carous/Carou.css"


const Carou = () => { 
    const location = useLocation()
        return(
            <>
                <section className="quisommesnous">
                <div className="header1"><br/>
                    <div className="aaaa" style={{backgroundColor : "rgba(0,0,0,0.5)", color:"#fff", width:"50%", marginLeft:"2cm", borderRadius:"50px"}}>
                        <br/><br/><br/>
                        <hr style={{width:"9%", height:".1cm", color:"#c61612", marginLeft:"4cm", opacity:"1"}}/>
                        <h4 style={{marginLeft:"4cm"}}>A propos de nous</h4><br/>
                        <p style={{marginLeft:"4cm", fontSize:"20px", color:"#fff"}}>Un partenaire qui délivre !</p><br/><br/>
                    </div>
                </div>
            </section>
        </>
        );
}
export default Carou;