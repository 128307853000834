import React from "react";
import Question from "./Question";
import {questions} from "./data";

const Faq = () =>{
    return(
        <section id="faq">
            <div className="container faq">
                <div className="questions">
                    <br/>
                    {
                        questions.map((question) =>(
                            <Question title={question.title} answer={question.answer}/>
                        ))
                    }
                </div>
            </div>
        </section>
    );
};
export default Faq;