import React from "react";
import "./Features.css";
import {BsFillBookmarkStarFill} from "react-icons/bs";
import log from "../../pic/log.jpg"
import Feature from "./Feature";
import {featureList} from "./data"
import {FaHandPointRight} from "react-icons/fa"

const Features = () => {
    return (
        <section id="features">
            <div className="container features">
                <div className="title"><br/>
                    <div className="lines">
                        <BsFillBookmarkStarFill color="rgba(198,22,18,255)" size={40} className="icone"/>
                    </div><br/>
                    <h5 style={{fontSize:"28px"}}>Besoin d'un transport quotidien ou en Urgence ? <span style={{color:"#c62616"}}>R</span>&<span style={{color:"#c62616"}}>K</span> Transport est là!</h5>

                    <p style={{fontSize:"16px"}} className="u-text-small u-text-dark">R&K Transport assure depuis plusieurs années des Transports et Livraisons professionnels de produits aux quatre coins du Blog !
                    Votre satisfaction est notre priorité !</p>
                </div>

                <div className="features-content">
                    <div className="a">
                        <div className="a-left">
                            <div className="a-card bg"></div>
                            <div className="a-card">
                                <img src={log} className="a-img"/>
                            </div>
                        </div>
                    </div>                    
                    <div className="features-right">
                        {
                            featureList.map((feature) =>(
                                <Feature key={feature.id} icon={feature.icon} heading={feature.heading} text={feature.text}/>
                            ))
                        }
                    </div>
                </div>
                
                <div className="title" style={{marginTop:"-2cm"}}>
                    <h5>Notre expertise !</h5>
                    <p className="u-text-small u-text-dark" style={{fontSize:"15px"}}>Nous sommes bien plus qu’une simple entreprise de transport. Nous collaborons avec nos clients dans plusieurs secteurs d’activités pour créer des solutions de transports et logistiques innovantes et sur mesure.<br/>En un mot, nous vous aidons à améliorer vos process et à optimiser vos résultats.</p><br/>
                    <hr style={{width:"60%" , marginLeft:"7cm" , color:"gray", height:"2px"}}/>
                </div>
                
                <div className="row" style={{display:"flex", width:"95%" , marginTop:"-.5cm"}}>
                    <div className="col-5">
                        <div className="title"><h5 style={{color:"rgba(198,22,18,255)" , fontSize:"26px" , marginBottom:"-1cm"}}>Sur la route</h5></div><br/>
                        <FaHandPointRight style={{color:"rgb(90, 177, 206)"}}/><p style={{fontSize:"15px", marginLeft:".7cm", marginTop:"-.5cm"}}>Livraisons spécialisée avec main d’œuvre possible.</p><br/>
                        <FaHandPointRight style={{color:"rgb(90, 177, 206)"}}/><p style={{fontSize:"15px", marginLeft:".7cm", marginTop:"-.5cm"}}>Livraison du rdv / délais connus à l'avance.</p><br/>
                        <FaHandPointRight style={{color:"rgb(90, 177, 206)"}}/><p style={{fontSize:"15px", marginLeft:".7cm", marginTop:"-.5cm"}}>Fonctionnement 24h/7j.</p><br/>
                        <FaHandPointRight style={{color:"rgb(90, 177, 206)"}}/><p style={{fontSize:"15px", marginLeft:".7cm", marginTop:"-.5cm"}}>Des chauffeurs compétents et bien présentés.</p>
                    </div>       
                    <div className="col-2"></div> 
                    <div className="col-5">
                        <div className="title"><h5 style={{color:"rgba(198,22,18,255)" , fontSize:"26px" , marginBottom:"-1cm"}}>Au bureau</h5></div><br/>
                        <FaHandPointRight style={{color:"rgb(90, 177, 206)"}}/><p style={{fontSize:"15px", marginLeft:".7cm", marginTop:"-.5cm"}}>Bonne communication.</p><br/>
                        <FaHandPointRight style={{color:"rgb(90, 177, 206)"}}/><p style={{fontSize:"15px", marginLeft:".7cm", marginTop:"-.5cm"}}>Des tarifs compétitifs, simples, flexibles et transparents.</p><br/>
                        <FaHandPointRight style={{color:"rgb(90, 177, 206)"}}/><p style={{fontSize:"15px", marginLeft:".7cm", marginTop:"-.5cm"}}>Réponse rapide assurée.</p><br/>
                        <FaHandPointRight style={{color:"rgb(90, 177, 206)"}}/><p style={{fontSize:"15px", marginLeft:".7cm", marginTop:"-.5cm"}}>Une équipe opérationnelle professionnelle, réactive et motivée.</p><br/>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Features;