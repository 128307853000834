const PopularData = [
    {
        id:1,
        image : require("../../../pic/aa.jpg"),
        country :"aaaaa",
        name :"aaaaaaaaaa",
        price :"$aaaaa",
    },
    {
       id:2,
       image : require("../../../pic/aa.jpg"),
       country :"aaaaa",
       name :"aaaaaaaaaa",
       price :"$aaaaa",
   },
   {
       id:3,
       image : require("../../../pic/aa.jpg"),
       country :"aaaaa",
       name :"aaaaaaaaaa",
       price :"$aaaaa",
   },
   {
       id:4,
       image : require("../../../pic/aa.jpg"),
       country :"aaaaa",
       name :"aaaaaaaaaa",
       price :"$aaaaa",
   },
   {
       id:5,
       image : require("../../../pic/aa.jpg"),
       country :"aaaaa",
       name :"aaaaaaaaaa",
       price :"$aaaaa",
   },
   {
       id:6,
       image : require("../../../pic/aa.jpg"),
       country :"aaaaa",
       name :"aaaaaaaaaa",
       price :"$aaaaa",
   },
]
export default PopularData