import React from "react";
import {FaQuoteLeft} from "react-icons/fa";

const Card = (props) =>{
    return(
        <>
            <div className="box">
                <FaQuoteLeft className="ic"/>
                <div className="para">
                    <p>{props.desc}</p>
                </div>
                <hr/>
                <div className="details flex">
                    <div className="img">
                        <img src={props.profile} alt=""/>
                    </div>
                    <div className="name">
                        <h4>{props.name}</h4>
                        <span>{props.post}</span>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Card;