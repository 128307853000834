import React from "react";
import { Date } from "../../components/Date/Date";
import { Navbaar } from "../../components/Navbar/Navbaar";
import "./Quisommesnous.css"
import About from "../../components/About/About";
import Gallery from "../../components/Gallery/Gallery";
import Destinations from "../../components/Destination/Home";
import DestinationHome from "../../components/HomeSection/Destina/DestinationHome";
import Aide from "../../components/Aide/Aide";
import Footer from "../../components/Footer/Footer";
import Carou from "../../components/Carous/Carou";
import MostPopular from "../../components/HomeSection/Popular/MostPopular";
import Testimonial from "../../components/Testimonial/Testimonial";
import Brandingg from "../Flotte/Brandingg";

 
const Quisommesnous = () =>{
        return(
            <> 
                <Date/><hr style={{ color : 'gray'}}/>
                <Navbaar/>
                <Carou/>
                <div style={{backgroundColor:"#e9eff2"}}>

                <br/><br/>
                <div style={{ width:"95%", backgroundColor:"#fff", marginLeft:"1cm",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
                    <br/><div className="line"></div><br/>
                    <p style={{textAlign:"center", color:"#878e99", fontSize:"17px", lineHeight:"30px", marginTop:"25px", width:"90%", marginLeft:"2.1cm"}}>Pour une livraison fiable, flexible et économiquement performante, R&K Transport permet à ses clients d'étre livrés rapidement, par le mode de livraison le plus adapté, tout en restant informés au fil de l'eau. Conjuger fiabilité Logistique et maitrise des couts devient un enjeu stratégique.</p>
                    <About/>
                </div><br/><br/>
                <div style={{marginTop:"-2.8cm", width:"95%", backgroundColor:"#fff", marginLeft:"1cm",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
                    <DestinationHome/><br/>
                </div>  <br/><br/><br/><br/>
                <div style={{ width:"95%", backgroundColor:"#fff", marginLeft:"1cm",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
                <Testimonial/><br/><br/><br/>
                </div>
                <Brandingg/>
                <Footer/>
                
                </div>
            </>
        );
    }

    export default Quisommesnous;