import React from "react";
import "./Presentation.css";
import { Typewriter} from 'react-simple-typewriter'
import "./Presentation.css";


const Presentation =() => {
    return (
    <>
        <section className="presentation" id="home">
            <div className="container f_flex top">
                <div style={{marginTop:"-2cm"}}>
                    <h4>
                        Externalisez votre<span> Logistique</span>
                    </h4>
                    <h5>
                        R&K vous offre <span><Typewriter
                            words={['un Transport Urgent', 'un Transport discret', 'un Transport spéciaa', 'un Transport national et international','un Stockage adapté et sécurisé','une Préparation de commandes','une Gestion flexible de stock']}
                            loop
                            cursor
                            cursorStyle='|'
                            typeSpeed={70}
                            deleteSpeed={50}
                            delaySpeed={1000}
                        /></span>
                    </h5><br/>
                    <p style={{textAlign:"center", fontSize:"15px"}}>Pour une livraison fiable, flexible et économiquement performante, R&K Transport permet à ses clients d'étre livrés rapidement, par le mode de livraison le plus adapté, tout en restant informés au fil de l'eau. Conjuger fiabilité Logistique et maitrise des couts devient un enjeu stratégique.</p><br/><br/>
                </div>
            </div>
        </section>
             

    </>
)
};
export default Presentation