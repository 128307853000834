import React from "react";
import { Link } from "react-router-dom";
import {FaLongArrowAltRight} from "react-icons/fa";
import accc from "../../pic/accc.jpg"
import acc from "../../pic/acc.jpg"
import acccc from "../../pic/acccc.jpg"
const BlogCard = () =>{
    return(
        <>
            <div className="items">
                <div className="img">
                    {/*cover */}
                    <img src={accc} alt=""/>
                </div>
                <div className="category flex_space">
                    {/*date */}
                    <span>2015-2022</span>
                    {/*category */}
                    <label>Transport</label>
                </div>

                <div className="details">
                    {/*Title */}
                    <h4>Express !</h4>
                    {/*para */}
                    <p style={{fontSize:"13px"}}>R&K Transport vous offre des services de Transports urgents, discrets, spéciaux, nationaux et internationaux !</p>
                </div>
                
                <Link to={`/blogsingle`} className="blogItem-link">
                    <FaLongArrowAltRight className="ico" style={{marginTop:".6cm"}}/><p style={{marginLeft:".7cm", marginTop:"-.46cm"}}>Voir plus</p>
                </Link>
            </div>

            <div className="items">
                <div className="img">
                    {/*cover */}
                    <img src={acc} alt=""/>
                </div>
                <div className="category flex_space">
                    {/*date */}
                    <span>2015-2022</span>
                    {/*category */}
                    <label>Stockage</label>
                </div>

                <div className="details">
                    {/*Title */}
                    <h4>Logistique !</h4>
                    {/*para */}
                    <p style={{fontSize:"13px"}}>R&K Transport vous assure une gestion flexible de stock, ainsi qu'un stockage adapté et bien sécurisé !</p>
                </div>

                <Link to={`/blogsinglee`} className="blogItem-link">
                    <FaLongArrowAltRight className="ico" style={{marginTop:".6cm"}}/><p style={{marginLeft:".7cm", marginTop:"-.46cm"}}>Voir plus</p>
                </Link>
            </div>

            <div className="items">
                <div className="img">
                    {/*cover */}
                    <img src={acccc} alt=""/>
                </div>
                <div className="category flex_space">
                    {/*date */}
                    <span>2015-2022</span>
                    {/*category */}
                    <label>Livraison</label>
                </div>

                <div className="details">
                    {/*Title */}
                    <h4>Messagerie !</h4>
                    {/*para */}
                    <p style={{fontSize:"13px"}}>R&K Transport vous affre un transport où les marchandises sont portées directement de l'expéditeur au destinateur !</p>
                </div>

                <Link to={`/blogsingleee`} className="blogItem-link">
                    <FaLongArrowAltRight className="ico" style={{marginTop:".6cm"}}/><p style={{marginLeft:".7cm", marginTop:"-.46cm"}}>Voir plus</p>
                </Link>
            </div>
            
        </>
    );
}
export default BlogCard;    