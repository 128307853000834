import React from "react";
import "./Flotte.css"
const About=()=>{

    const data=[
        {
            title:"zebjdizekdjncdsck",
            desc1:"Ce n'est un secret pour personne que le transport de marchandises de haute qualité et sûr est étroitement lié à la flotte de véhicules de l’entreprise de logistique. Les véhicules des entreprises doivent être constamment prêts à prendre la route, l'assurance voiture de société doit toujours être valable, les véhicules doivent se trouver en bon état technique.",
            desc2:"Adaptez vos capacités de stockage et votre réseau en temps réel. Avec notre système GPS, on peut toujours suivre les camions et leurs localisations.",
            desc3:"Spacefill offre des capacités illimitées de stockage et de transport avec le plus haut niveau de service et de connectivité possible.",
            cover :require("../../pic/accc.jpg")
        }
    ]
    return(
        <>
            <section className="aabout topMargin">
                <div className="coont grrid">
                    {
                        data.map((val)=>{
                            return <div className="left mtop">
                                <div className="aboutCard mtop flex_space" style={{marginTop:"0cm"}}>
                                    <div className="row row1" style={{marginLeft:"1cm", width:"130vh"}}>
                                        <h4 style={{marginLeft:"-2cm"}}>Découvrez notre Flotte !</h4>
                                        <h1 style={{marginLeft:"-2cm"}}>R&K Transport met à votre <span>disposition</span> une flotte de <span>Véhicules</span> !</h1>
                                    </div>
                                </div>
                                <div className="heading1"><br/>
                                    <p>{val.desc1}</p>
                                    <p>{val.desc2}</p>
                                    <p>{val.desc3}</p><br/>
                                </div>
                            </div>
                        })
                    }
                </div>
            </section>
        </>
    )
}
export default About