import React, { useState } from "react";
import Basic from "./Basic";
import SearchBox from "./SearchBox";
import "./index.css"


const Leaflet=()=>{
    const [selectPosition , setSelectPosition] = useState(null);


    return(
        <>
        <div style={{marginTop:"-1cm"}}>
            <div>
                <Basic id="maap" selectPosition={selectPosition} />
            </div><br/>

        </div>
        
        </>
    )
}
export default Leaflet;