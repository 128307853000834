import React, { useState , useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Navbaar } from "../Navbar/Navbaar";
import {BiLeftArrowAlt} from "react-icons/bi"
import Sdata from "../Destination/Sdata";
import EmptyFile from "../EmptyFile/EmptyFile";
import "./SinglePage.css"
import {FaPhoneAlt} from "react-icons/fa"
import {ImLocation} from "react-icons/im"
import {BsEnvelopeFill} from "react-icons/bs"
import Aide from "../Aide/Aide";
import Footer from "../Footer/Footer";
import {Date} from "../Date/Date"
import jjj from "../../pic/jjj.webp"
import nnn from "../../pic/nnn.png"
import nnnn from "../../pic/nnnn.jpg"
import {FaHandPointRight} from "react-icons/fa"
import "./Hero1.css";
import {MdVerticalAlignTop} from "react-icons/md"
import Retour from "./Retour";
import But from "./But";
import Branding from "../../pages/Flotte/Branding";
import { fontSize } from "@mui/system";

const SinglePage = () =>{
    return (
        <>
            <Date/>
            <hr style={{ color : 'gray'}}/>
            <Navbaar/>
            

            <div className="hero1"><br/>
                <div className="content1"> 
                    <p style={{justifyContent:"center", alignItems:"center", marginTop:"-9cm" ,fontSize:"37px"}}>A propos de Nous !</p><br/>
                    <p style={{fontSize:"1.4rem" ,textShadow:"black 0.2em 0.2em 0.2em"}}>Découvrez notre équipe</p>
                </div>
            </div>

            
        <div style={{backgroundColor:"#e9eff2", marginTop:"0cm"}}><br/><br/><br/>
        <div style={{ width:"90%", backgroundColor:"#fff", marginLeft:"2cm",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
            <br/><div className="line" style={{marginTop:"1.4cm"}}></div>
            <section className="single-page top">
                <div className="container">
                <Retour/>
                    
                    <article className="content flex">
                        <div className="main-content">
                            <p style={{marginTop:"-1cm"}}>Nous sommes une société de transport routier à Genas, ayant pour mission de vous apporter le meilleur service dans le cadre d'une externalisation rentable.</p>
                            <div className="para flex_space">
                                <p>Nous souhaitons devenir votre partenaire Transport et vous soulager de cette gestion en respectant vos demandes !</p>
                            </div><br/>
                            <img src={jjj} alt="" style={{marginTop:".06cm", opacity:".9" , height:"7cm", width:"11cm"}}/><br/><br/><br/>
                            <p style={{width:"7.2cm", marginLeft:"12cm", marginTop:"-8.9cm"}}>En tant que professionnels, notre objectif initial était d’uniformiser les règles du jeu dans le domaine du transport, livraison, express... Nous avons fini par nous donner pour mission de révolutionner l’industrie du transport, grâce à une équipe extraordinaire de professionnels passionnés</p>
                            
                            <But/>
                            <br/><br/><br/><br/>
                            <h1 style={{color:"#c61612"}}>Découvrez notre équipe !</h1><br/>
                            

                            
                            <div className="image grid1">
                                <img src={nnn} alt="" className="imaage" style={{height:"230px"}}/>
                                <img src={nnnn} alt="" className="imaage" style={{height:"230px"}}/>
                            </div>
                            <br/><br/>
                            <h5 style={{color:"#c61612", marginTop:".5cm"}}><FaHandPointRight/>
                                <span style={{color:"gray", marginLeft:".9cm"}}><p style={{marginTop:"-1.2cm", marginLeft:".9cm", fontSize:".6cm"}}>Nous suscitons l’enthousiasme de nos clients</p></span>
                            </h5>
                            <p>Nous sommes ici pour créer des expériences remarquables. Nous faisons en sorte que les choses se fassent. Par-dessus tout, nous sommes passionnés par ce que nous faisons et nous visons à susciter l’enthousiasme de nos clients, rien de moins.</p>
                            <h5 style={{color:"#c61612"}}><FaHandPointRight/>
                                <span style={{color:"gray"}}><p style={{marginTop:"-.55cm", marginLeft:".9cm", fontSize:".6cm"}}>Nous privilégions le travail d’équipe</p></span>
                            </h5>
                            <p>Les personnes passent avant tout le reste. Nous nous soucions de ceux avec qui nous travaillons et de ceux pour qui nous travaillons. Nous nous attachons à établir des relations de confiance et des partenariats gagnant-gagnant. Nous nous soucions des résultats, mais pas au détriment des personnes. L’équipe passe en premier, tout le reste en second.</p>
                            <h5 style={{color:"#c61612"}}><FaHandPointRight/>
                                <span style={{color:"gray"}}><p style={{marginTop:"-.55cm", marginLeft:".9cm", fontSize:".6cm"}}>Nous communiquons des solutions</p></span>
                            </h5>
                            <p>Notre curiosité suscite un besoin d’apprendre sans fin. Nous pouvons toujours être meilleurs et faire mieux. C’est pour cela que nous nous concentrons sur la communication de solutions, et non de problèmes. Nous acceptons les erreurs, mais ne les répétons pas.</p>
                            <h5 style={{color:"#c61612"}}><FaHandPointRight/>
                                <span style={{color:"gray"}}><p style={{marginTop:"-.55cm", marginLeft:".9cm", fontSize:".6cm"}}>Nous bâtissons sur la confiance</p></span>
                            </h5>
                            <p>La confiance est notre fondement, donc tout ce que nous faisons participe à créer la confiance. Nous prenons les choses en main et faisons preuve de responsabilité à tous les niveaux et sur tous les fronts.</p>
                            <h5 style={{color:"#c61612"}}>&nbsp;&nbsp;
                                <span style={{color:"gray"}}></span>
                            </h5>
                            <p></p>

                        </div>

                        <div className="side-content" style={{marginTop:"2cm", width:"20px", marginLeft:".5cm"}}>
                            <div className="box" style={{height:"12.5cm"}}>
                                <br/><h2 style={{fontSize:"21.5px"}}>Rejoignez l'équipe de <span style={{color:"#c61612"}}>R</span >&<span style={{color:"#c61612"}}>K Transport </span>!</h2><br/>
                                <br/><span><ImLocation style={{fontSize:"24px", color:"#c61612"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"17px"}}>&nbsp;&nbsp;&nbsp;<span><p style={{fontSize:"18px", marginLeft:"1cm", marginTop:"-1.2cm"}}>Adresse</p></span></span></span><br/><p style={{color:"gray", marginTop:"-1cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 Rue Marcel Dassault, 69740 Genas</p>
                                <span><FaPhoneAlt style={{fontSize:"24px", color:"#c61612"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"17px"}}>&nbsp;&nbsp;&nbsp;&nbsp;<span><p style={{fontSize:"18px", marginLeft:"1cm", marginTop:"-1.2cm"}}>Téléphone</p></span></span></span><br/><p style={{color:"gray", marginTop:"-1cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="tel:+788073409" className='mmm' >0487627661</a></p>
                                <span><BsEnvelopeFill style={{fontSize:"24px", color:"#c61612"}}/><span style={{color:"black", fontWeight:"bold", fontSize:"17px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span><p style={{fontSize:"18px", marginLeft:"1cm", marginTop:"-1.2cm"}}>E-mail</p></span></span></span><br/><p style={{color:"gray", marginTop:"-1cm"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href = "mailto: contact@rktransport.fr" className='mm'>contact@rktransport.fr</a></p>
                                <Link to="/contact" style={{cursor:"pointer"}}>
                                    <button className="outline-btn" id="bbb" style={{marginLeft:"1.5cm", outline:"none", border:"none",marginTop:"0.2cm", background: "#c61612", color:"#fff", height:"50px"}}>
                                        <FaPhoneAlt className="icone" style={{height:"20px", width:"20px", marginLeft:"-.3cm", marginTop:"0cm"}}/>&nbsp;&nbsp;&nbsp;<span><p style={{marginLeft:".7cm",marginTop:"-1.1cm",fontSize:"16px", color:"white", cursor:"pointer"}}>Contactez-nous</p></span>
                                    </button>
                                </Link>
                                </div>  
                        </div>
                    </article>
                </div>
                
            </section>
            </div>
            <Branding/>
            <Footer/>
            </div>
        </>
    );
}
export default SinglePage;