import React, { useState } from "react";
import BlogCard from "./BlogCard";
import BlogData from "./BlogData";
import "./BlogHome.css"

const AllBlog = () =>{
    return(
        <>
            <section className="blog top">
                <div className="container">
                    <div className="content grid">
                        <BlogCard />
                    </div>
                </div>
            </section>
        </>
    );
}
export default AllBlog;