import React from "react";
import {FaExternalLinkAlt} from "react-icons/fa";
import { Link } from "react-router-dom";
import car from "../../pic/car.jpg"

const Dcarddd = () =>{
    return(
        <>
            <div className="items">
                <div className="img"> 
                    <img src={car} alt=""/>

                    <Link to="/singlepageee" className="blogItem-link">
                        <FaExternalLinkAlt className="icone"/>
                    </Link>
                </div>

                <div className="title">
                    <h3 style={{fontSize:"17px", marginTop:".5cm"}}>Votre carrière, Postulez !</h3>
                </div>
            </div>
        </>
    );
}
export default Dcarddd;