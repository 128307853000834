import React from "react";
import location from "../../../pic/location.png"
import mail from "../../../pic/mail.png"
import call from "../../../pic/call.png"
const Foo =()=>{
    return(
        <>
            <section className="secttt" id="foo">
                <form className="cv" style={{width:"300px", marginLeft:"11cm"}}>
                    <div className="contactInfoo" style={{height:"450px"}}>
                        <div>
                            <h2>Pour nous joindre !</h2><br/><br/>
                                <ul className="infoo">
                                    <li className="llii">
                                        <span ><img  src={location} alt=""/></span>
                                        <span>
                                            1 rue Marcel Dassault,<br/>
                                            69740 Genas,<br/>
                                            Lyon
                                        </span>
                                    </li>
                                    <li className="llii">
                                        <span ><img  src={mail} alt=""/></span>
                                        <span>
                                            <a href = "mailto: contact@rktransport.fr" className='mm'>contact@rktransport.fr</a>
                                        </span>
                                    </li><br/>
                                    <li className="llii">
                                        <span ><img  src={call} alt=""/></span>
                                        <span>
                                            <a href="tel:+487627661" className='mm'>0487627661</a>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="contactFormm" style={{height:"620px"}}>
                            {/*item.devis */}
                            <h2>Demander un devis <span style={{color:"#c6302d"}}>Messagerie</span> !</h2>
                            <div className="formBoxx">
                                <div className="inputBoxx w50">
                                    <label htmlFor="nom"></label>
                                    <input type="text" required name="nom" className="nom"/>
                                    {/*item.nom */}
                                    <span htmlFor="nom">Votre nom</span>
                                </div>
                                <div className="inputBoxx w50">
                                    <label htmlFor="email"></label>
                                    <input type="text" required name="email" className="email"/>
                                    {/*item.email */}
                                    <span htmlFor="email">Votre E-mail</span>
                                </div>
                                <div className="inputBoxx w50">
                                    <label htmlFor="telephone"></label>
                                    <input type="number" required name="telephone" className="telephone"/>
                                    {/*item.telephone */}
                                    <span htmlFor="telephone">Votre Téléphone</span>
                                </div>
                                <div className="inputBoxx w50">
                                    <label htmlFor="societe"></label>
                                    <input type="text" required name="societe" className="societe"/>
                                    {/*item.societe */}
                                    <span htmlFor="societe">Nom de la société</span>
                                </div>
                                <div className="inputBoxx w50">
                                    <label htmlFor="date" style={{color:"#c6302d"}}>Date</label>
                                    <input type="date" required name="date" className="date"/>
                                    {/*item.datee */}
                                    <span htmlFor="date"></span>
                                </div>
                                <div className="inputBoxx w100">
                                    <label htmlFor="message"></label>   
                                    <textarea required name="message" className="message"></textarea>
                                    <span htmlFor="message">Saisir votre message....</span>
                                </div>            
                                <div className="inputBoxx w100">
                                    <input type="submit"></input><div className="msg">Message envoyé !</div>
                                </div>
                        </div>
                    </div>
                </form>
            </section><br/><br/><br/>
        </>
    )
}
export default Foo