import React from "react";
import "./About.css";
import zz from "../../pic/zz.jpg";
const AboutCard = () =>{
    return(
        <>
            <div className="aboutCard mtop flex_space" style={{marginTop:"-1cm"}}>
                <div className="row row1">
                    <h4>A propos de nous</h4>
                    <h1>Votre <span>Satisfaction,</span> notre motivation !</h1>
                    <p style={{fontSize:"14.5px", color:"gray"}}>R&K Transport est une société de transport créé en 2015. qui a pour objectif d'organiser l'acheminement de vos marchandises et d'assurer le suivi et la bonne exécution du transport en vous appuyant sur un réseau fiable et contrôlé avec soin en France et en Europe, et ce dans les meilleures conditions.</p>
                    <p style={{fontSize:"14.5px", color:"gray"}}>Qu'il s'agisse de pli, de colis ou de palette, nous vous proposons la solution transport adéquate. Ainsi, nous nous adaptons à vos impératifs de livraison en vous proposant un large panel de service: le transport urgent, discret, spécial, au national et à l’international. La logistique avec le stockage adapté et sécurisé de vos marchandises, la préparation de commandes et la gestion de stock. La partie messagerie avec la gestion de tournées régulières ou occasionnelles.</p>
                </div>
                <div className="row image">
                    <img src={zz} alt="" style={{width:"91%", height:"10cm"}}/>
                </div>
            </div>
        </>
    )
}

export default AboutCard;