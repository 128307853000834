import React from "react";
import { useState } from "react";
import "./Flotte.css"
import ee from "../../pic/ee.jpeg"


function Tabs(){


    return(
        <div >
            
        </div>
    )
}
export default Tabs