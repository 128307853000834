import React, { useEffect, useRef, useState } from "react";
import {MapContainer , Marker, Popup, TileLayer , useMap} from "react-leaflet";
import osm from "./osm-providers"
import "./index.css"
import "leaflet/dist/leaflet.css"
import "leaflet/dist/leaflet"
import L from "leaflet"
import cities from "./cities.json"
import "leaflet-control-geocoder/dist/Control.Geocoder.css"
import "leaflet-control-geocoder/dist/Control.Geocoder.js"


const markerIcon = new L.Icon({
    iconUrl : require("../../pic/marker.png"),
    iconSize : [45 , 47],
    iconAnchor:[17 , 45],
    popupAnchor: [0,-46],
});

const position = [45.727611426526046, 4.984068269090909]

function ResetCenterView(props){
    const { selectPosition } = props;
    const map = useMap();

    useEffect(()=>{
        if(selectPosition){
            map.setView(
                L.latLng(selectPosition?.lat, selectPosition?.lon),
                map.getZoom(),
                {
                    animate : true
                }
            )
        }
    },[selectPosition]);

    return null;
}

const Basic =(props)=>{
    const { selectPosition } = props;
    const locationSelection = [selectPosition?.lat, selectPosition?.lon]
    
    

    return(
        <>
            <div className="row" style={{width:"100%"}}>
                <div className="col text-center">

                    <div className="col">
                        <MapContainer id="leaflet" center={position} zoom={13} >
                            <TileLayer url={osm.maptiler.url} attribution={osm.maptiler.attribution}/>
                                    <Marker position={position} icon={markerIcon}>
                                        <Popup>
                                            <b>R&K Transport <br/>1 rue Marcel Dassault, <br/>Genas</b>
                                        </Popup>
                                    </Marker>
                            {
                                selectPosition && (
                                    <Marker position={locationSelection} icon={markerIcon}>
                                    </Marker>
                                )
                            }

                            <ResetCenterView selectPosition={selectPosition} />
                        </MapContainer>
                    </div>
                </div>
            </div> 
            
        </>
    )
}
export default Basic;