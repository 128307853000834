import React from "react";
import { Date } from "../../components/Date/Date";
import Gallery from '../../components/Gallery/Gallery'
import Footer from "../../components/Footer/Footer";
import Leaflet from "../../components/Leaflet/Leaflet"
import { ChakraProvider, theme } from '@chakra-ui/react'
import { Navbaar } from "../../components/Navbar/Navbaar";
import Brandinggg from "../Flotte/Brandinggg";
import location from "../../pic/location.png"
import mail from "../../pic/mail.png"
import call from "../../pic/call.png"
import "./Contact.css"

const Contact = () => {
  return (
    <>
      <Date/><br/><hr style={{ color : 'gray'}}/><br/>
        

            <section className="quisommesnous" style={{marginTop:"2.4cm"}}>
                <div className="header3"><br/>
                    <div className="aaaa" style={{backgroundColor : "rgba(0,0,0,0.5)", color:"#fff", width:"50%", marginLeft:"2cm", borderRadius:"50px"}}>
                        <br/><br/><br/>
                        <hr style={{width:"9%", height:".1cm", color:"#c61612", marginLeft:"4cm", opacity:"1"}}/><br/>
                        <h4 style={{marginLeft:"4cm"}}>Contactez nous !</h4><br/><br/>
                        <p style={{marginLeft:"4cm", fontSize:"20px", color:"#fff"}}>Vous avez déjà fait la moitié du chemin</p><br/><br/>
                    </div>
                </div>
            </section>
            <section style={{backgroundColor:"#e9eff2"}}><br/><br/><br/>
              <div style={{ width:"96%", backgroundColor:"#fff", marginLeft:".9cm",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
                <br/><div className="line"></div><br/>
                <p style={{textAlign:"center", color:"#878e99", fontSize:"16px", lineHeight:"30px", marginTop:"25px", width:"90%", marginLeft:"2.1cm"}}>Vous cherchez une information précise sur le groupe Société Générale ou l’une de ses entités ? Nous sommes à votre disposition pour vous répondre rapidement.</p>
                <br/><br/><br/><br/>
                      <div className="aboutCard mtop flex_space" style={{marginTop:"-1cm"}}>
                          <div className="row row1">
                              <h4 style={{marginLeft:"3.5cm"}}>DITES NOUS TOUT</h4>
                              <h1 style={{marginLeft:"3.5cm"}}>Votre <span>Satisfaction,</span> notre motivation !</h1>
                          </div>
                      </div>
                  <Gallery/>
                  <ChakraProvider theme={theme}>
              
                  <section className="sett">
                                <form className="cv" style={{width:"300px"}}>
                                    <div className="contactIn">
                                        <div>
                                            <h2 style={{color:"white"}}>Pour nous joindre !</h2><br/><br/>
                                            <ul className="inf">
                                                <li className="llli">
                                                    <span ><img  src={location} alt=""/></span>
                                                    <span style={{color:"white"}}>
                                                        1 rue Marcel Dassault,<br/>
                                                        69740 Genas,<br/>
                                                        Lyon
                                                    </span>
                                                </li>

                                                <li className="llli">
                                                    <span ><img  src={mail} alt=""/></span>
                                                    <span style={{color:"white"}}>
                                                    <a href = "mailto: contact@rktransport.fr" className='mm'>contact@rktransport.fr</a>
                                                    </span>
                                                </li><br/>
                                                <li className="llli">
                                                    <span ><img  src={call} alt=""/></span>
                                                    <span style={{color:"white"}}>
                                                    <a href="tel:+788073409" className='mmm' >0487627661</a>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="contactFoorm">
                                        {/*item.devis */}
                                        <h2>Contactez-nous ! <span style={{color:"#c6302d"}}>Réclamez !</span></h2>
                                        <div className="formBxxx">
                                            <div className="inputBxxx w50">
                                                <label htmlFor="nom"></label>
                                                <input type="text" required name="nom" className="nom"/>
                                                {/*item.nom */}
                                                <span htmlFor="nom">Votre nom</span>
                                            </div>

                                            <div className="inputBxxx w50">
                                                <label htmlFor="prenom"></label>
                                                <input type="text" required name="prenom" className="prenom"/>
                                                {/*item.prenom */}
                                                <span htmlFor="prenom">Votre prénom</span>
                                            </div>

                                            <div className="inputBxxx w50">
                                                <label htmlFor="telephone"></label>
                                                <input type="number" required name="telephone" className="telephone"/>
                                                {/*item.telephone */}
                                                <span htmlFor="telephone">Votre Téléphone</span>
                                            </div>

                                            <div className="inputBxxx w50">
                                                <label htmlFor="email"></label>
                                                <input type="text" required name="email" className="email"/>
                                                {/*item.email */}
                                                <span htmlFor="email">Votre E-mail</span>
                                            </div>

                                            <div className="inputBxxx w100">
                                                <label htmlFor="message"></label>   
                                                <textarea required name="message" className="message"></textarea>
                                                <span htmlFor="message">Saisir votre message....</span>
                                            </div>
                                            
                                            <div className="inputBxxx w100">
                                                <input type="submit"></input>
                                            </div>
                                    </div>
                                </div>
                            </form>
                        </section>
                <Leaflet/>
                </ChakraProvider>
              </div>
              <Brandinggg/>
              <Footer/>
        </section>
        <div style={{marginTop:"-89.4cm"}}>
          <Navbaar/>
        </div>
    </>
  )
}
  
 
export default Contact;