import React from "react";
import accc from "../../../pic/accc.jpg"

const Adapte=()=>{
    return(
        <div id="adapte">
            <section className="Logistique topMargin" style={{width:"180vh"}}>
                <div className="coont">
                    <div className="contain grid topMargin">
                        <>
                            <div className="boox">
                                <div className="imgg">
                                    <img src={accc} alt=""/>
                                </div>
                                <div className="text">
                                    <h2>Stockage adapté et sécurisé</h2>
                                    <p>L’entrepôt logistique est comme une fourmilière en perpétuel mouvement, dans laquelle la sécurité des marchandises reste un enjeu majeur. R&K Transport préserve vos produits stockés ou en transit, de manière optimale.</p>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Adapte