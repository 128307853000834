import React from "react";
import './Feature.css';
import {BsHexagon} from "react-icons/bs";


const Feature =({icon,heading,text}) =>{
    return (
        <div className="feature">
            <div className="feature-icon">
                <BsHexagon color="rgba(198,22,18,255)" size={55}/>
                <div className="inner-icon">
                    {icon}
                </div>
            </div>
            <div className="feature-text">
                <h3 style={{fontSize:"24px"}}>{heading}</h3>
                <p className="u-text-small" style={{fontSize:"15px", color:"gray"}}>{text}</p>
            </div>
        </div>
    );
}
export default Feature;