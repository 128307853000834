import React from "react";
import logi from "../../../pic/logi.jpg"

const Discrets=()=>{
    return(
        <div id="discrets">
            {/*item.cover */}
            <img src={logi} alt="" style={{marginTop:".06cm", opacity:".8", height:"250px", width:"360px"}}/>
            <h4 style={{color:"#c61612", marginLeft:"10.6cm", marginTop:"-6cm", fontSize:"21px"}}>Transports Discrets</h4>
            <p style={{color:"black", marginLeft:"10.8cm", fontSize:"13.5px"}}>En réponse à la demande de ses clients historiques, exigeant haut professionnalisme et discrétion totale, R&K Transport a développé et entretient un savoir-faire unique de transport de valeur discret dans le plus strict respect de la réglementation.</p>
            <br/><br/>
            <hr/>
        </div>
    )
}
export default Discrets