import React from "react";
import { Date } from "../../components/Date/Date";
import "./Flotte.css";
import { Navbaar } from "../../components/Navbar/Navbaar";
import Tabs from "./Tabs.js"
import Branding from "./Branding";
import About from "./About.js"
import Card from "./Card.js"
import Footer from "../../components/Footer/Footer";


const Flotte = ()=>{
    return (
        <>
            <Date/><hr style={{ color : 'gray'}}/>
            <Navbaar/>
            <div className="hero3"><br/>
                <div className="content3"> 
                    <p style={{justifyContent:"center", alignItems:"center", marginTop:"-9cm", fontSize:"38px"}}>Notre Flotte !</p><br/>
                    <p style={{fontSize:"1.3rem" ,textShadow:"black 0.2em 0.2em 0.2em"}}>R&K Transport met à votre disposition une flotte de véhicule disponibles 7 jours/7, 24 heures/24</p>
                </div>
            </div>
            
            <div style={{backgroundColor:"#e9eff2"}}><br/>
                <div style={{ width:"90%", backgroundColor:"#fff", marginLeft:"2cm",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
                    <br/><p style={{textAlign:"center", color:"#878e99", fontSize:"16px", lineHeight:"30px", marginTop:"25px", width:"90%", marginLeft:"2.1cm"}}>Pour un service toujours au plus près de vos besoins, nous assuront un roulement permanent avec une quinzaine de véhicules, sans flocage et géolocalisés, de tous types.</p>
                    <About/>
                    <Card/>
                </div>
                <Branding/>
                <Footer/>
            </div>
            <div style={{backgroundColor:"#e9eff2"}}><br/>
                <div style={{ width:"90%", backgroundColor:"#fff", marginLeft:"2cm",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>

               </div>
            </div>
        </>               
    )
}
export default Flotte