import React from "react";
import "./Flotte.css"


const Card =()=>{

    const carddata=[
        {
            id:1,
            title:"Flotte T1",
            desc:";e,jdnkzjne zjedzieuhdizeud zedjuzouzkd,z dkznjdozjzoklkqsdozuhedozuednkz edjnzoed zehidzhebdjbe",
            cover:require("../../pic/acc.jpg"),
            poids:"Poids : 250 KG",
            hauteur:"Hauteur : 0.80 M",
            largeur:"Largeur ( Passage Roue ) : 1 M",
            longueur:"Longueur : 1.50 M"
        },
        {
            id:2,
            title:"Flotte T2",
            desc:";e,jdnkzjne zjedzieuhdizeud zedjuzouzkd,z dkznjdozjzoklkqsdozuhedozuednkz edjnzoed zehidzhebdjbe",
            cover:require("../../pic/acc.jpg"),
            poids:"Poids : 1T200",
            hauteur:"Hauteur : 1.25 M / 1.30 M",
            largeur:"Largeur ( Passage Roue ) : 1.20 M",
            longueur:"Longueur : 2.35 M / 2.80 M "
        },
        {
            id:3,
            title:"Flotte T3",
            desc:";e,jdnkzjne zjedzieuhdizeud zedjuzouzkd,z dkznjdozjzoklkqsdozuhedozuednkz edjnzoed zehidzhebdjbe",
            cover:require("../../pic/acc.jpg"),
            poids:"Poids : 1T400",
            hauteur:"Hauteur : 1.80 M",
            largeur:"Largeur ( Passage Roue ) : 1.45 M",
            longueur:"Longueur : 3.5 M"
        },
        {
            id:4,
            title:"Flotte T4",
            desc:";e,jdnkzjne zjedzieuhdizeud zedjuzouzkd,z dkznjdozjzoklkqsdozuhedozuednkz edjnzoed zehidzhebdjbe",
            cover:require("../../pic/acc.jpg"),
            poids:"Poids : 750 M",
            hauteur:"Hauteur : 2.10 M",
            largeur:"Largeur ( Passage Roue ) : 2 M",
            longueur:"Longueur : 4 M"
        },
        {
            id:5,
            title:"Flotte T4 Bashe",
            desc:";e,jdnkzjne zjedzieuhdizeud zedjuzouzkd,z dkznjdozjzoklkqsdozuhedozuednkz edjnzoed zehidzhebdjbe",
            cover:require("../../pic/acc.jpg"),
            poids:"Poids : 750 KG",
            hauteur:"Hauteur : 1.90 M",
            largeur:"Largeur ( Passage Roue ) : 2 M",
            longueur:"Longueur : 4 M"
        },
    ]
    return(
        <>
            <section className="crd topMargin">
                <div className="coont">
                    <h1 className="heaa">
                        <span>N</span>
                        <span>o</span>
                        <span>t</span>
                        <span>r</span>
                        <span>e</span>
                        <span className="space"></span>
                        <span>F</span>
                        <span>l</span>
                        <span>o</span>
                        <span>t</span>
                        <span>t</span>
                        <span>e</span>
                        <span className="space"></span>
                        <span>!</span>
                    </h1><br/>
                    <div className="contain grrid topMargin">
                        {carddata.map((val)=>{
                            return <div className="bxx">
                                <div className="igm">
                                    <img src={val.cover} alt=""/>
                                </div>
                                <div className="txtt">
                                    <h2 className="text-center">{val.title}</h2><br/>
                                    <p style={{fontSize:"14.5px"}}>{val.poids}</p>
                                    <p style={{fontSize:"14.5px"}}>{val.hauteur}</p>
                                    <p style={{fontSize:"14.5px"}}>{val.largeur}</p>
                                    <p style={{fontSize:"14.5px"}}>{val.longueur}</p>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </section>
        </>
    )
}
export default Card