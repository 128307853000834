import React from "react";
import accc from "../../../pic/accc.jpg"

const Stock=()=>{
    return(
        <div id="stock">
            <section className="Logistique topMargin" style={{width:"180vh"}}>
                <div className="coont">
                    <div className="contain grid topMargin">
                        <>
                            <div className="boox">
                                <div className="imgg">
                                    <img src={accc} alt=""/>
                                </div>
                                <div className="text">
                                    <h2>Gestion de Stock</h2>
                                    <p>Nos solution apporte une vision complète de vos stocks en temps réel tout en optimisant l’espace et l’organisation de votre entrepôt pour garantir l’optimisation, la fiabilité et la rapidité de vos préparations de commandes.</p>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Stock