export const questions =[
    {
        id:1,
        title:"La réactivité",
        answer:"C'est notre priorité, nous savons ce qu'est une urgence client et ferons tout pour la traiter rapidement car votre réussite est la notre.",
    },
    {
        id:2,
        title:"La souplesse",
        answer:"Nous vous accueillerons toujours avec le sourire sans nous retrancher derrière des procédures.",
    },
    {
        id:3,
        title:"La transparence",
        answer:"Notre objectif n'est pas de facturer du stockage ou de la réception de marchandises, mais de vous accompagner avec plaisir.",
    },
    {
        id:4,
        title:"Excellence",
        answer:"R&K Transport et le chois rigoureux de nos outils sont là pour nous permettre d'atteindre cet objectif. Nous vous accompagnons avec des solutions souples, réactives et transparentes."
    }
]
