import React from "react";
import { Link } from "react-router-dom";
import {FaPhoneAlt} from "react-icons/fa"


const Buut =()=>{
    return(
        <>
            <Link to="/contact" style={{cursor:"pointer"}}>
                <button className="outline-btn" id="bbb" style={{marginLeft:"1.5cm", outline:"none", border:"none",marginTop:"0.2cm", background: "#c61612", color:"#fff", height:"50px"}}>
                    <FaPhoneAlt className="icone" style={{height:"20px", width:"20px", marginLeft:"-.3cm", marginTop:"0cm"}}/>&nbsp;&nbsp;&nbsp;<span><p style={{marginLeft:".7cm",marginTop:"-1.1cm",fontSize:"16px", color:"white", cursor:"pointer"}}>Contactez-nous</p></span>
                </button>
            </Link>
        </>
    )
}
export default Buut