import React from "react";
import accc from "../../../pic/accc.jpg"

const Commande=()=>{
    return(
        <div id="commande">
            <section className="Logistique topMargin" style={{width:"180vh"}}>
                <div className="coont">
                    <div className="contain grid topMargin">
                        <>
                            <div className="boox">
                                <div className="imgg">
                                    <img src={accc} alt=""/>
                                </div>
                                <div className="text">
                                    <h2>Préparation de commandes</h2>
                                    <p>La préparation de commandes est une opération principale que R&K Transport assure et essaye de l'optimiser, pour permettre à la fois de gagner en productivité et à améliorer la qualité de service pour nos clients.</p>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Commande