import React from "react";
import { useState , useEffect } from "react";
import { useParams } from "react-router-dom";
import BlogData from"../BlogData"
import "./Head2.css"
import EmptyFile from "../../EmptyFile/EmptyFile";
import "./Head2.css"
import yyy from "../../../pic/yyy.jpeg"
const Head = () =>{

    return(
        <>

            <div className="t">
                <div className="tt">
                    {/*item.titre */}
                    <span className="ttt">Transport</span>
                </div>
                {/*item.imaaage*/}
                <img className="tttt" src={yyy} alt="" width={"100%"}/>
            </div>
        </>
    );
}
export default Head;