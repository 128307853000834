import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router , Routes , Route } from "react-router-dom";
import Contact from "./pages/Contact/Contact";
import { Services } from "./pages/Services/Services";
import Quisommesnous from "./pages/Quisommesnous/Quisommesnous";
import Accueil from "./pages/Accueil";
import SinglePage from './components/SinglePage/SinglePage';
import BlogSingle from './components/Blog/blog-single-page/BlogSingle';
import Flotte from './pages/Flotte/Flotte';
import Error from './components/Error/Error';
import BlogSinglee from './components/Blog/blog-single-page/BlogSinglee';
import BlogSingleee from './components/Blog/blog-single-page/BlogSingleee';
import Urgents from './components/Blog/blog-single-page/Urgents';
import Discrets from './components/Blog/blog-single-page/Discrets';
import Speciaux from './components/Blog/blog-single-page/Speciaux';
import Nationaux from './components/Blog/blog-single-page/Nationaux';
import Adapte from './components/Blog/Logistique/Adapte';
import Commande from './components/Blog/Logistique/Commande';
import Stock from './components/Blog/Logistique/Stock';
import Caart from './components/Blog/blog-single-page/Caart';
import SinglePagee from './components/SinglePage/SinglePagee';
import SinglePageee from './components/SinglePage/SinglePageee';
import Foo from './components/Blog/blog-single-page/Foo';
import Leaflet from './components/Leaflet/Leaflet';

ReactDOM.render(

  <Router>
    <Routes>
      <Route path='/' element={<Accueil/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/quisommesnous' element={<Quisommesnous/>} />
      <Route path='/services' element={<Services/>} />
      <Route path='/singlepage' element={<SinglePage/>} />
      <Route path='/singlepagee' element={<SinglePagee/>} />      
      <Route path='/singlepageee' element={<SinglePageee/>} />
      <Route path='/blogsingle' element={<BlogSingle/>} />
      <Route path='/blogsinglee' element={<BlogSinglee/>} />
      <Route path='/blogsingleee' element={<BlogSingleee/>} />
      <Route path='/flotte' element={<Flotte/>}/>
      <Route path='/error' element={<Error/>}/>
      <Route path='/urgents' element={<Urgents/>}/>
      <Route path='/discrets' element={<Discrets/>}/>
      <Route path='/speciaux' element={<Speciaux/>}/>
      <Route path='/nationaux' element={<Nationaux/>}/>
      <Route path='/adapte' element={<Adapte/>}/>
      <Route path='/commande' element={<Commande/>}/>
      <Route path='/stock' element={<Stock/>}/>
      <Route path='/caart' element={<Caart/>}/>
      <Route path='/foo' element={<Foo/>}/>
      <Route path='/leaflet' element={<Leaflet/>}/>
    </Routes>
  </Router>,

  document.getElementById('root')
);


