import React, { useEffect, useState } from "react";
import "./Hero.css"
import ExpandMoreIcone from "@material-ui/icons/ExpandMore"
import { Zoom, IconButton } from "@material-ui/core";
import useWindowPosition from "../../hook/useWindowPosition";
import {Link as Scroll} from "react-scroll"


export default function Hero(){
    const [checked , setChecked] = useState(false);

    const check = useWindowPosition('caard');

    useEffect(()=>{
        setChecked(true);
    },[])
    return(
        <div className="hero"><br/>
        <Zoom in={checked} { ... (checked ? {timeout: 500} : {})} collapsedHeight={50}>
            <div className="content"> 
                <p style={{justifyContent:"center", alignItems:"center", fontSize:"38px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LIVRER DE L’EFFICACITÉ & OPTIMISER VOTRE Transport !</p><br/>
                <p style={{fontSize:"1.6rem" ,textShadow:"black 0.2em 0.2em 0.2em"}}>Un Transport complet et intelligent</p>
                <p style={{fontSize:"1.1rem", textShadow:"black 0.03em 0.03em 0.2em"}}>C’est aussi simple que ça</p><br/>
                <Scroll to="caard" smooth={true}>
                    <IconButton>
                        <ExpandMoreIcone className="expaand" style={{fontSize:"4rem"}}/>
                    </IconButton>  
                </Scroll>     
            </div>
        </Zoom>
        <div className="cartaa" id="caard" style={{display:"flex", justifyContent:"center", alignItems:"center", marginTop:"-2.6cm" , }}>

        </div>
        </div>
    )
}


