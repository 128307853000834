import React from "react";
import jjjj from "../../../pic/jjjj.jpg"

const Speciaux =()=>{
    return(
        <div id="speciaux">
            {/*item.cover */}
            <img src={jjjj} alt="" style={{marginTop:".06cm", opacity:".8", height:"250px", width:"360px"}}/>
            <h4 style={{color:"#c61612", marginLeft:"10.6cm", marginTop:"-5.7cm", fontSize:"21px"}}>Transports Spéciaux</h4>
            <p style={{color:"black", marginLeft:"10.8cm", fontSize:"13.5px"}}>La réactivité et l'efficacité du transport express font exclusivement parties de notre savoir-faire. Votre participation est primordiale : testez-nous, et indiquez-nous les villes d’expédition et de destination de vos produits, accompagnées de leurs codes postaux avec le jour et l’ heure de livraison pour rendre nos réponses encore plus rapides !</p>
            <hr/>
        </div>
    )
}
export default Speciaux