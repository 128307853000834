import {FaPlayCircle} from "react-icons/fa"

const BlogData = [
    {
        id:1,
        date : "2015-2022",
        category:"Transport", 
        title:"Express !",
        cover: require("../../pic/accc.jpg"),
        para:"R&K Transport vous offre des services de Transports urgents, discrets, spéciaux, nationaux et internationaux !",
        desc:"",
        imaaage:require("../../pic/yyy.jpeg"),
        titre:"Transport Express ",
        p1:"Transports Urgents",
        icone1:<FaPlayCircle/>,
        p2:"Transports Discrets",
        icone2:<FaPlayCircle/>,
        p3:"Transports Spéciaux",
        icone3:<FaPlayCircle/>,
        p4:"Transports Nationaux et Internationaux",
        icone4:<FaPlayCircle/>,
        txt:"La réponse à l’extrême urgence",
        par:"Rupture de chaîne, documents importants, urgence médicale, RESEAU EXPRESS contacte le chauffeur le plus proche du lieu d’enlèvement pour un transport de pli, colis ou palette en express par coursier dans des délais optimisés. L’enlèvement peut être immédiat, mais l’horaire peut également être à la convenance de l’expéditeur. Nous vous garantissons un transport express sans rupture de charge dans des conditions de sécurité optimales. Grâce à notre système de suivi, vous pouvez consulter l’avancement de votre course urgente en temps réel.",
        parag:"Sur simple appel téléphonique ou via devis express, nous sommes en mesure de mettre à votre disposition immédiate un véhicule adapté à votre demande et son chauffeur. Tous nos partenaires sont sélectionnés avec des critères de qualité très stricts vous garantissant une fiabilité totale dans la réalisation de votre course urgente. L'utilisation d'un logiciel d'optimisation d'itinéraire est une garantie de fiabilité dans l'exécution de votre transport express.",
        txt1:"Le transport Express maîtrisé",
        par1:"Grâce à nos outils de tracking (suivi de transport express en temps réel), nous pouvons connaître l'avancement de votre course et vous informer à chaque étape de la livraison. Nous pouvons mettre à votre disposition exclusive une large gamme de véhicules pouvant prendre en charge vos marchandises de 1 gramme à 25 tonnes, ainsi qu'un coursier.",
        txt2:"Le transport express depuis les principales villes d’Europe",
        txt3:"Demander Un devis !",
        par2:"Véritable réseau européen de transport express, nous expédions vos marchandises depuis toutes les villes en France et en Europe pour une livraison directe par coursier, en messagerie express ou bien en affrètement. Pour un transport express frigorifique, de matériel lourd ou de matière dangereuse, contactez-nous au 0487627661 pour connaître nos délais d’intervention sur votre région.",
        nom:"Votre nom",
        email:"Votre Email",
        telephone:"Votre Téléphone",
        societe:"Votre Société",
        chargement:"Point de Chargement",
        livraison:"Point de Livraison",
        devis:"Express"
    },
    {
        id:2,
        date : "2017-2022",
        category:"Stockage",
        title:"Logistique !",
        cover: require("../../pic/acc.jpg"),
        para:"R&K Transport vous assure une gestion flexible de stock, ainsi qu'un stockage adapté et bien sécurisé !",
        desc:"",
        imaaage:require("../../pic/yyy.jpeg"),
        titre:"Logistique ",
        p1:"Stockage adapté et sécurisé",
        icone1:<FaPlayCircle/>,
        p2:"Préparation de commandes",
        icone2:<FaPlayCircle/>,
        p3:"Gestion de stock",
        icone3:<FaPlayCircle/>,
        txt:"Confiez Votre Stock et La Gestion de Votre Logistique à R&K Transport",
        par:"Trouver sans plus attendre votre solution de stockage professionnel et logistique. Grâce à son réseau de logisticien, R&K Transport vous accompagne afin de trouver une solution adaptée à vos besoins de stockage et de logistique à Lyon et ses alentours.",
        par1:"Nos solutions en logistique ainsi que notre expertise vous permettront de gagner du temps et de l’argent. Confiez la chaîne logistique de votre entreprise à des spécialistes !",
        txt2:"R&K Transport facilite votre distribution !",
        txt3:"Demande de stockage",
        nom:"Votre nom",
        email:"Votre Email",
        telephone:"Votre Téléphone",
        societe:"Votre Société",
        chargement:"Point de Chargement",
        livraison:"Point de Livraison",
        devis:"Logistique"
    },
    {
        id:3,
        date : "2019-2022",
        category:"Livraison",
        title:"Messagerie !",
        cover: require("../../pic/acccc.jpg"),
        para:"R&K Transport vous affre un transport où les marchandises sont portées directement de l'expéditeur au destinateur !",
        desc:"",
        imaaage:require("../../pic/yyy.jpeg"),
        titre:"Messagerie ",
        txt:"Le transport en messagerie express",
        par:"Idéal pour les expéditions n’ayant pas un caractère de première urgence, nous livrons vos plis, vos colis et vos palettes en messagerie Express. Pour être performant tous les jours, vous avez besoin d'un partenaire pour le transport et la messagerie express qui respecte vos engagements.  ",
        par1:"Envois sur-mesure, pour tous les besoins d’expéditions hors normes, les envois sensibles ou les livraisons en extrême urgence. Solutions sectorielles : adaptées aux contraintes de certains secteurs comme la santé, les banques et les assurances, l’industrie automobile ou le monde de l’informatique, de l’électronique et de la téléphonie.",
        par2:"Expéditions standards en express, à horaire garanti.",
        txt2:"Réduisez vos coûts et déchargez vous du souci de vos expéditions",
        txt3:"Demande de messagerie",
        nom:"Votre nom",
        email:"Votre Email",
        telephone:"Votre Téléphone",
        societe:"Votre Société",
        chargement:"Point de Chargement",
        livraison:"Point de Livraison",
        devis:"Messagerie",
    }
]
export default BlogData;