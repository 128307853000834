import { Link } from "react-router-dom";
import React from "react";
import logo from "../../pic/logo.png";
import "./Footer.css"
import {FaFacebookF , FaInstagram } from "react-icons/fa";
import {TiSocialGooglePlus} from "react-icons/ti";


const Footer = () =>{
    return(
        <div className="body">
            <section className="footer">

                <ul className="list">
                    <li>
                        <Link to="/">Accueil</Link>
                    </li>
                    <li>
                        <Link to="/quisommesnous">Qui sommes nous ?</Link>
                    </li>
                    <li>
                        <Link to="/services">Nos services</Link>
                    </li>
                    <li>
                        <Link to="/flotte">Notre Flotte</Link>
                    </li>
                    <li>
                        <Link to="/contact">Contactez-nous</Link>
                    </li>
                </ul><br/><hr style={{width:"70%", color:"gray" , marginLeft:"6cm"}}/>
                <p className="copyright">Khammassi Imen - 2022 © Tous les droits sont resérvés</p>
            </section>
        </div>
    );
}

export default Footer;