import {FaHandPointRight} from "react-icons/fa"

const Sdata=[
    {
        id:1,
        title:"Découvrez notre équipe",
        image : require("../../pic/jjj.webp"),
        desc :"Nous sommes une société de transport routier à Genas, ayant pour mission de vous apporter le meilleur service dans le cadre d'une externalisation rentable.",
        descri :"En tant que professionnels, notre objectif initial était d’uniformiser les règles du jeu dans le domaine du transport, livraison, express... Nous avons fini par nous donner pour mission de révolutionner l’industrie du transport, grâce à une équipe extraordinaire de professionnels passionnés",
        sidepara :"Nous souhaitons devenir votre partenaire Transport et vous soulager de cette gestion en respectant vos demandes !",
        paraImage_one : require("../../pic/nnn.png"),
        paraImage_two: require("../../pic/nnnn.jpg"),
        h51:"Nous suscitons l’enthousiasme de nos clients",
        p1:"Nous sommes ici pour créer des expériences remarquables. Nous faisons en sorte que les choses se fassent. Par-dessus tout, nous sommes passionnés par ce que nous faisons et nous visons à susciter l’enthousiasme de nos clients, rien de moins.",
        h52:"Nous privilégions le travail d’équipe",
        p2:"Les personnes passent avant tout le reste. Nous nous soucions de ceux avec qui nous travaillons et de ceux pour qui nous travaillons. Nous nous attachons à établir des relations de confiance et des partenariats gagnant-gagnant. Nous nous soucions des résultats, mais pas au détriment des personnes. L’équipe passe en premier, tout le reste en second.",
        h53:"Nous communiquons des solutions",
        p3:"Notre curiosité suscite un besoin d’apprendre sans fin. Nous pouvons toujours être meilleurs et faire mieux. C’est pour cela que nous nous concentrons sur la communication de solutions, et non de problèmes. Nous acceptons les erreurs, mais ne les répétons pas.",
        h54:"Nous bâtissons sur la confiance",
        p4:"La confiance est notre fondement, donc tout ce que nous faisons participe à créer la confiance. Nous prenons les choses en main et faisons preuve de responsabilité à tous les niveaux et sur tous les fronts.",
        icone1: <FaHandPointRight/>,
        icone2: <FaHandPointRight/>,
        icone3: <FaHandPointRight/>,
        icone4: <FaHandPointRight/>,
        im : require("../../pic/rr.jpg"),
        titre:"Découvrez notre équipe",
    },
    {
        id:2,
        title:"Notre objectif, mission, vision",
        image : require("../../pic/obj.png"),
        desc :"Nous aidons les entreprises à déplacer leurs marchandises rapidement, efficacement. En développant des partenariats étroits avec nos clients, nous nous assurons qu’ils obtiennent un service logistique qui complète leur propre qualité de service.",
        sidepara :"Nous sommes des spécialistes du transport et de la messagerie fournissant des véhicules dédiés pour assurer le service le plus rapide et le plus efficace pour nos clients !",
        paraImage_one : require("../../pic/ooo.jpg"),
        paraImage_two: require("../../pic/oooo.jpg"),
        h51:"Notre objectif",
        p1:"R&K Transport a pour objectif d'organiser l'acheminement de vos marchandises et d'assurer le suivi et la bonne exécution du transport en vous appuyant sur un réseau fiable et contrôlé avec soin en France et en Europe, et ce dans les meilleures conditions.",
        h52:"Notre mission",
        p2:"Nous aidons les entreprises à déplacer leurs marchandises rapidement, efficacement. En développant des partenariats étroits avec nos clients, nous nous assurons qu’ils obtiennent un service logistique qui complète leur propre qualité de service. Nous sommes des spécialistes du transport et de la messagerie fournissant des véhicules dédiés pour assurer le service le plus rapide et le plus efficace pour nos clients.",
        h53:"Notre vision",
        p3:"Il s’agit de remettre en question la pensée conventionnelle et de concevoir un service de logistique sur mesure adapté à votre activité et qui respecte l’environnement. Nous ajoutons de la valeur en travaillant avec vous pour concevoir et développer une solution logistique qui comprend les enjeux de demain.",
        icone1: <FaHandPointRight/>,
        icone2: <FaHandPointRight/>,
        icone3: <FaHandPointRight/>,
        titre:"Notre objectif, mission, vision",
    },
    {
        id:3,
        title:"Carrière",
        image : require("../../pic/car.jpg"),
        descri:"Nous sommes toujours à la recherche de personnes passionnées",
        sidepara :"Vous souhaitez nous aider à révolutionner le secteur du transport et logistique ?",
        paraImage_one : require("../../pic/cc.webp"),
        paraImage_two: require("../../pic/lll.jpg"),
        hh1:"Changez de voie",
        pp1:"Votre motivation est pour nous plus importante que des notes, des diplômes ou un CV complet. Chaque changement est synonyme de progrès et nous le savons.",
        hh2:"Esprit d'équipe",
        pp2:"Si vous êtes à la recherche d'une structure hiérarchisée et stricte, vous n'êtes pas au bon endroit. Nous travaillons sur un pied d'égalité où tout le monde à son mot à dire.",
        hh3:"Esprit libre",
        pp3:"Vous aimez travailler de manière indépendante et sur une base de confiance. Pour nous, c'est le résultat qui compte, pas le nombre d'heures travaillées.",
        hh4:"Pensez différemment",
        pp4:"Vous ne serez pas enfermé dans une boîte de pensée. Trouvez vos propres solutions innovantes, loin des schémas habituels.",
        h33:"Qu'est-ce qui vous attend ?",
        h51:"Horaires de travail flexibles",
        p1:"Nous savons que tout le monde n'est pas le plus productif de 9 heures à 17 heures. C'est pourquoi nous encourageons nos employés à établir leurs propres horaires de travail flexibles qui sont coordonnés avec leurs équipes respectives.",
        h52:"Abonnement club de sports offert",
        p2:"Nos activités professionnelles sont diversifiées, mais nos déplacements le sont moins. Afin de créer un équilibre, nous proposons à tous les employés de byrd un abonnement à l'Urban Sports Club (Berlin) et à MyClubs (Vienne).",
        h53:"Bureaux branchés",
        p3:"Que ce soit directement au cœur de Vienne, ou dans le quartier branché de Berlin, nos bureaux sont bien plus qu'un simple lieu de travail.",
        h54:"Événements d'équipe réguliers",
        p4:"Nous sommes toujours heureux de nous retrouver pour des déjeuners d'équipe ou une petite pause pour se détendre. De plus, les événements de team-building et de fête sont au cœur de nos rencontres sociales, réunissant les collègues basés à Berlin et à Vienne pour célébrer nos succès et notre croissance.",
        h55:"Boissons & Snacks",
        p5:"Nous savons que travailler sur notre mission nécessite une énergie suffisante. C'est pourquoi nous offrons du café, des boissons, des fruits frais, des collations saines (et moins saines), pour être pleins d'énergie chaque jour.",
        icone1: <FaHandPointRight/>,
        icone2: <FaHandPointRight/>,
        icone3: <FaHandPointRight/>,
        icone4: <FaHandPointRight/>,
        icone5: <FaHandPointRight/>,
        titre:"Nous recrutons !",
    },
]
export default Sdata;