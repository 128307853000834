import React from "react";
import acccc from "../../../pic/acccc.jpg"

const Nationaux =()=>{
    return(
        <div id="nationaux">
            {/*item.cover */}
            <img src={acccc} alt="" style={{marginTop:".06cm", opacity:".8", height:"250px", width:"360px"}}/>
            <h4 style={{color:"#c61612", marginLeft:"10.6cm", marginTop:"-6cm", fontSize:"21px"}}>Transports Nationaux et internationaux</h4>
            <p style={{color:"black", marginLeft:"10.8cm", fontSize:"13.5px"}}>Fournir un produit de qualité signifie pour nous acheminer rapidement et efficacement. Pour y parvenir, nous organisons quotidiennement des transports à destination et en provenance de la France, afin de pouvoir répondre efficacement à vos attentes.</p>
            <br/><hr/><br/><br/>
        </div>
    )
}
export default Nationaux;