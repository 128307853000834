import React from "react";
import AboutCard from "./AboutCard";
import dd from "../../pic/dd.jpg"
const About = () =>{
    return(
        <>
            <section className="about top">
                <div className="container">
                    <AboutCard/>
                </div>
            </section>

            <section className="features top">
                <div className="container aboutCard flex_space" style={{marginTop:"-1cm"}}>
                    <div className="row row1">
                        <h1>Notre <span>expertise</span></h1>
                        <p style={{fontSize:"14.5px", color:"gray"}}>Notre expertise s'est renforcée au travers de notre réseau (contrôlé et audité) nous permettant de vous proposer des solutions complètes et spécifiques afin de répondre à vos exigences : véhicule adéquat, RDV, matière dangereuse, stockage de vos marchandises, chargement en vrac, palettisation, transport exceptionnel...</p>
                        <p style={{fontSize:"14.5px", color:"gray"}}>Attentif et à l'écoute de vos demandes et contraintes, nous mettons tous les moyens possibles pour répondre à vos attentes et vous suggérons la meilleure solution. Que ce soit en termes de coût ou de service.</p>
                        <p style={{fontSize:"14.5px", color:"gray"}}>En tant que professionnels, nous savons nous adapter aux aléas inhérents au transport ( prise de RDV, modification des colisages, des adresses, attentes, échange palette... ) et en cas de nécessité, nous pouvons assurer le stockage de vos marchandises.</p>
                    </div>

                    <div className="row image">
                        <img src={dd} alt="" style={{width:"100%", height:"92%"}}/>
                    </div>
                </div>
            </section><br/><br/>
        </>
    )
}
export default About;