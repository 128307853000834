import React from "react";
import Card from "./Card";
import GalleryData from "./GalleryData";
import "./Gallery.css"
import yyy from "../../pic/yyy.jpeg"
import acc from "../../pic/acc.jpg"
import car from "../../pic/car.jpg"
import acccc from "../../pic/acccc.jpg"
import jjj from "../../pic/jjj.webp"
import obj from "../../pic/obj.png"
import {BiLeftArrowAlt} from "react-icons/bi"
import { Link } from "react-router-dom";

const Gallery = () =>{
    return(
        <>
            <br/><section className="gall" id="gall">
                <h1 className="heaa">
                    <span>C</span>
                    <span>o</span>
                    <span>n</span>
                    <span>t</span>
                    <span>a</span>
                    <span>c</span>
                    <span>t</span>
                    <span>e</span>
                    <span>z</span>
                    <span className="space"></span>
                    <span>n</span>
                    <span>o</span>
                    <span>u</span>
                    <span>s</span>
                    <span>!</span>
                </h1>
                <div className="box-cont">
                    <div className="boxxx">
                        <img src={yyy} alt=""/>
                        <div className="cnt">
                            <h3>Express </h3>
                            <p>R&K Transport vous offre des services de Transports urgents, discrets, spéciaux, nationaux et internationaux !</p>
                            <Link to="/blogsingle" style={{color:"white"}}>
                                <button className="bttn btn1">Voir plus</button>
                            </Link>
                        </div>
                    </div>
                    <div className="boxxx">
                        <img src={acc} alt=""/>
                        <div className="cnt">
                            <h3>Logistique</h3>
                            <p>R&K Transport vous assure une gestion flexible de stock, ainsi qu'un stockage adapté et bien sécurisé !</p>
                            <Link to="/blogsinglee" style={{color:"white"}}>
                                <button className="bttn btn1">Voir plus</button>
                            </Link>
                        </div>
                    </div>
                    <div className="boxxx">
                        <img src={acccc} alt=""/>
                        <div className="cnt">
                            <h3>Messagerie</h3>
                            <p>R&K Transport vous affre un transport où les marchandises sont portées directement de l'expéditeur au destinateur !</p>
                            <Link to="/blogsingleee" style={{color:"white"}}>
                                <button className="bttn btn1">Voir plus</button>
                            </Link>
                        </div>
                    </div>
                    <div className="boxxx">
                        <img src={jjj} alt=""/>
                        <div className="cnt">
                            <h3>Notre équipe</h3>
                            <p>Nous nous attachons à établir des relations de confiance gagnant-gagnant. L’équipe passe en premier, tout le reste en second.</p>
                            <Link to="/singlepage" style={{color:"white"}}>
                                <button className="bttn btn1">Voir plus</button>
                            </Link>
                        </div>
                    </div>
                    <div className="boxxx">
                        <img src={obj} alt=""/>
                        <div className="cnt">
                            <h3>Notre objectif</h3>
                            <p>R&K Transport a pour objectif d'organiser l'acheminement des marchandises et d'assurer le suivi en vous appuyant sur un réseau fiable</p>
                            <Link to="/singlepagee" style={{color:"white"}}>
                                <button className="bttn btn1">Voir plus</button>
                            </Link>
                        </div>
                    </div>
                    <div className="boxxx">
                        <img src={car} alt=""/>
                        <div className="cnt">
                            <h3>R&K Transport recrute</h3>
                            <p>Si vous souhaitez nous aider à révolutionner le secteur du transport et logistique, n'hésitez pas à joindre l'équipe R&K Transport !</p>
                            <Link to="/singlepageee" style={{color:"white"}}>
                                <button className="bttn btn1">Voir plus</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section><br/><br/><br/><br/><br/>
        </>
    );
}
export default Gallery;